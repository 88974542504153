@import "../../theme/variables";
@import "../../theme/mixins";

.wrap {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.subform {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: $colorItem5;

  .header {
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 16px 16px 4px 4px;
    background-color: $laneColorPrHeader;
    color: $colorTextContrast;
    font-weight: 700;
    font-size: 18px;

    .header-icon {
      margin: 0 12px 0 16px;
      width: 16px;
      height: 16px;
    }
  }

  .form-body {
    display: flex;
    flex-direction: column;
    padding: 16px 16px;

    @include for-size(tablet-portrait-up) {
      padding: 16px 72px;
    }
  }
}

.table {
  display: table;
  width: 100%;
  border-spacing: 0 20px;
  border-collapse: separate;
  border-style: hidden;
  table-layout: fixed;
}

.row {
  display: table-row;

  @include for-size(phone-only) {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.cell {
  display: table-cell;
  padding: 4px 0;
  vertical-align: top;
}

.first-col {
  width: 150px;
}

.second-col {
  width: auto;
}

.label {
  composes: label from "../FieldLabel/FieldLabel.module.scss";

  margin-right: 16px;
  font-size: 14px;

  &--required {
    composes: label--required from "../FieldLabel/FieldLabel.module.scss";
  }

  &--secondary {
    font-weight: 400;
  }
}

.input {
  composes: input from "../Field/Field.module.scss";

  padding-left: 8px;
  width: 200px;
  height: 34px;

  @include for-size(tablet-portrait-up) {
    width: 320px;
  }
}

.company-details {
  margin-bottom: 64px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin: 40px 16px 0 0;
}
