@import "../../theme/variables";

.section-wrap {
  display: flex;
  justify-content: space-between;
}

.organization-details {
  margin-right: 32px;
  line-height: 23px;
}

.edit {
  font-weight: bold;
  font-size: 15px;
}

.side-section {
  flex-grow: 1;
}

.checkmark {
  width: 16px;
  height: 16px;
  fill: $colorMedium;

  &--checked {
    fill: $colorSuccess;
  }
}

.our-team-table,
.business-units-table {
  border-collapse: collapse;
}

.margin-top {
  margin-top: 48px;

  &--small {
    margin-top: 16px;
  }
}

.table-header {
  border-bottom: 1px solid $colorShade;
  font-weight: bold;
  font-size: 12px;
}

.team-member {
  font-weight: bold;
}

.email {
  text-decoration: underline;
}

.edit {
  fill: $colorSuccess;
}

.security-section-title {
  margin-bottom: 4px;
  font-weight: $medium;
}

.disabled {
  color: $colorSecondary;
}

.white-list-info {
  margin-bottom: 16px;
}

.error {
  margin-bottom: 24px;
  color: $colorError;
  font-size: 12px;
}

.delete-warning {
  margin: 24px 0 24px 0;
  color: $colorError;
  font-size: 12px;
}

.form {
  min-height: 376px;
}

.delivery-info-new-address {
  composes: delivery-info-new-address from "../OrderProgressView/PurchaseOrderModal.module.scss";

  margin-bottom: 24px;
}

.address-country-select {
  composes: address-country-select from "../OrderProgressView/PurchaseOrderModal.module.scss";
}

.delivery-info-new-address-buttons {
  composes: delivery-info-new-address-buttons from "../OrderProgressView/PurchaseOrderModal.module.scss";
}
