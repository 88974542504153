@import "../../theme/variables";
@import "../../theme/mixins";

.form-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > button:last-child {
    margin-left: 1.5rem;
    padding-left: 12px;

    svg {
      fill: white;
      height: 14px;
      margin-right: 6px;
    }
  }
}

.pr-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include for-size(desktop-up) {
    .request-section:nth-child(1) {
      order: 2;
    }

    .request-section:nth-child(2) {
      order: 1;
    }
  }
}

.pr-section {
  &--actions {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0;

    & > button:last-child {
      margin-left: 1.5rem;
      padding-left: 12px;

      svg {
        fill: white;
        height: 14px;
        margin-right: 6px;
      }
    }
  }
}

.pr-section {
  @include for-size(desktop-up) {
    &-title {
      border-bottom: 2px solid $subtitleColor;
      padding-bottom: 7px;
    }
  }
}

.pr-section {
  font-size: 0.85rem;
  width: 100%;

  &-subtitle {
    color: $subtitleColor;
    font-weight: 400;
    margin-bottom: 0;
  }

  &-title {
    text-transform: uppercase;
  }

  &-value,
  &-link {
    font-weight: bold;
  }

  @include for-size(desktop-up) {
    font-size: 1rem;

    &-title {
      margin-top: 1.5rem;
    }
  }

  @include for-size(phone-only) {
    &-subtitle {
      font-size: 0.85rem;
    }
  }
}

.pr-section-title {
  &--alt {
    border-bottom: 0;
  }
}

.pr-section-subtitle,
.pr-statuses {
  margin-top: 1rem;
}

.request-statuses {
  margin-top: 1rem;
}
