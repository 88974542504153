@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form {
  width: 100%;
}

.input-list {
  margin-bottom: 32px;
}

.submit-button {
  margin-bottom: 24px;
  margin-left: auto;
}

.inline-button {
  display: inline;
}
