@import "../../theme/variables";
@import "../../theme/mixins";

@keyframes dropdown-menu {
  from {
    opacity: 0;
    transform: translateY(-15%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.dropdown {
  @include scrollbar;

  position: relative;

  &__selector {
    position: relative;
    display: inline-block;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    &--has-arrow {
      padding-right: 28px;

      &:after {
        $size: 6px;

        position: absolute;
        top: 50%;
        right: 8px;
        width: 0;
        height: 0;
        border-top: $size solid #01bf3a;
        border-right: $size solid transparent;
        border-left: $size solid transparent;
        content: "";
        transform: translateY(-50%);
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    margin: 0;
    padding : 0;
    border-radius: $radiusLarge;
    background: #ffffff;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

    &--open {
      animation-name: dropdown-menu;
      animation-duration: 0.4s;
    }

    &--right {
      right: 0;
      left: unset;
    }

    &--hidden {
      display: none;
    }
  }

  &__list {
    overflow-y: auto;
    margin: 0;
    padding : 0;
    max-height: 400px;
  }

  &__item {
    color: #222222;
    list-style: none;
    font-size: 14px;
    cursor: pointer;

    &--nowrap {
      white-space: nowrap;
    }

    &:hover {
      background-color: #f0f8f3;
    }

    &:first-child {
      border-top-left-radius: $radiusLarge;
      border-top-right-radius: $radiusLarge;
    }

    &:last-child {
      border-bottom-right-radius: $radiusLarge;
      border-bottom-left-radius: $radiusLarge;

      // TODO: Make logout button a link instead
      button {
        border-bottom-right-radius: $radiusLarge;
        border-bottom-left-radius: $radiusLarge;
      }
    }

    a,
    button {
      display: inline-block;
      padding: 12px 24px;
      width: 100%;
      color: #000000;
      text-decoration: none !important; // !important overwrites Button component value
    }

    a {
      &:focus {
        outline: none;
        box-shadow: $globalFocus;
      }
    }

    // TODO: Make logout button a link instead
    button {
      display: inline-block;
      border: none;
      background: transparent;
      text-align: left;

      // TODO: Add custom focus styles to navigational items
      &:focus {
        outline: 2px solid #a9c8fe;
      }
    }
  }
}
