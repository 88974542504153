@import "../../theme/variables";

@media print {
  .dropdown-selector {
    display: none !important;
    visibility: hidden;
    border: 0px !important;
  }
}

.dropdown-content {
  animation: none;
}

.dropdown-list {
  max-width: 280px;
  max-height: 280px;
}

.dropdown-selector {
  border-radius: 50%;

  &:focus {
    box-shadow: 0 0 0 1px $colorSuccess;
  }

  &--disabled {
    cursor: auto;

    &:focus {
      box-shadow: none;
    }
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.list-avatar {
  margin-right: 16px;
}
