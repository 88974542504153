@import "../../theme/variables";
@import "../../theme/mixins";

.view {
  @include scrollbar;

  display: flex;
  flex-basis: 100vh;
  flex-direction: column;
  height: 100vh;

  @include for-size(phone-only) {
    display: block;
    height: auto;
  }
}

.content {
  display: flex; // make body fill maximum space
  overflow: auto;
  flex-direction: column; // make body fill maximum space
  flex-grow: 1;
  padding: 10px 10px 0;

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &__title {
      font-size: 1.6em;
      margin-bottom: 0;
    }
  }

  &-title {
    font-size: 1.5em;
    margin-bottom: 0.5rem;

    &__span {
      font-weight: 400;
    }
  }

  @include for-size(tablet-portrait-up) {
    padding: 20px 25px;
  }

  @include for-size(phone-only) {
    margin-top: 125px;
  }
}
