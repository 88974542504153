@import "../../theme/variables";

.correspondence-bar {
  padding: 8px;
  min-height: 100%;
  width: 400px;
  background-color: white;
}

.correspondence-accordion {
  margin-bottom: 12px;
}

.correspondence-email {
  margin: 12px 0 0 8px;
}
