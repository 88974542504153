@import "../../theme/variables.scss";

.select {
  display: block;
  margin-bottom: $fieldMargin;
  padding: 0 22px 0 4px;
  height: $addonHeight;
  border: 1px solid $colorMedium;
  border-radius: $radiusSmall;
  background: url(./DownArrowIcon.svg) calc(100% - 8px) / 9px 6px no-repeat #ffffff;
  cursor: pointer;

  appearance: none;

  &:active,
  &:focus {
    outline: 0;
  }

  // removes dotted outline in Firefox
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000000;
  }

  &--secondary {
    padding-right: 10px;
    background: url(./TriangleArrowIcon.svg) calc(100% - 2px) / 6px 6px no-repeat $colorShade;

    &:hover,
    &:focus {
      background: url(./TriangleArrowIcon.svg) calc(100% - 2px) / 6px 6px no-repeat darken($colorShade, 5%);
    }
  }

  &--short {
    width: $shortField;
  }

  .option {
    &[selected] {
      .placeholder {
        display: all;
      }
    }
  }

  .placeholder {
    display: none;
  }
}

.radio {
  margin-bottom: $fieldMargin;
}

table {
  .select {
    margin-bottom: 0;
  }
}

.information {
  display: flex;
  width: 100%;
}

.left-column {
  display: inline-block;
  width: 300px;
  font-weight: bold;
}
.right-column {
  display: inline-block;
  width: calc(100% - 300px);
}
