@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  padding: 16px 28px;
  font-size: 18px;
}

.description {
  display: flex;
  flex-direction: column;

  &_text {
    margin-bottom: 16px;
  }

  &_gif {
    align-self: center;
    max-width: 90%;
  }
}

.action {
  display: flex;
  align-items: center;
  margin-top: 24px;

  &_text {
    margin-right: 16px;
  }
}
