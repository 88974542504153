.text {
  display: inline-flex;
  flex-direction: column;

  &--wide {
    flex: 1;
  }

  + .text {
    margin-left: 16px;
  }
}

.title {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 14px;
}

.description {
  font-size: 13px;
}

.reduced-opacity {
  opacity: 0.6;
}
