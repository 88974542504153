.wrap {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.swimlane {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
  padding: 20px 10px;
  transition: background-color 300ms ease;

  &--over {
    background-color: rgb(167, 255, 167);
  }

  &:first-child {
    margin-left: 0;
  }

  h2 {
    margin: 0 0 20px;
  }
}

.swimlane-droppable {
  flex: 1;
  padding: 10px;
  background-color: rgb(245, 245, 245);
}

.create-new-card {
  position: absolute;
  top: 10px;
  right: 10px;
  left: 10px;
  padding: 8px;
  border: 2px dotted #cccccc;
  border-radius: 10px;
  background-color: #ffffff;
  opacity: 0;
  transition: opacity 300ms ease;

  &--visible {
    opacity: 1;
  }

  &--over {
    background-color: rgb(167, 255, 167);
  }
}

.card {
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  transition: background-color 300ms ease;

  &--dragging {
    background-color: rgb(109, 109, 255);
  }

  h3 {
    margin-bottom: 8px;
  }
}

.card-items {
  background-color: inherit;

  &--over {
    background-color: rgb(167, 255, 167);
  }
}

.item {
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid #eeeeee;

  &--dragging {
    background-color: rgb(109, 109, 255);
  }
}
