@import "../../theme/variables";

.section-wrap {
  display: flex;
  justify-content: space-between;
}

.container {
  &:last-child {
    margin-bottom: 0;
  }
}

.side-section {
  flex-grow: 1;
}

.contacts {
  margin-right: 32px;
}

.edit {
  font-weight: bold;
  font-size: 15px;
}

.button-add {
  margin-left: auto;
}
