@import "../../theme/variables";

.modal-inner-wrap {
  display: flex;
  overflow: auto; // works together with modal-content-wrap
}

.modal-content-wrap {
  display: flex;
  overflow: auto; // works together with modal-inner-wrap
  flex: 1;
}

.modal-content-inner-wrap {
  display: flex;
  flex-flow: column;
  align-self: center;
  max-height: 100%;
  width: 100%;
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 48px 8px 8px;
  max-width: 100%;
  max-height: calc(100vh - 56px); // 100vh minus top and bottom margin
  width: 736px;
  border-radius: $radiusSmall;
  background: $colorLight;

  &--wide {
    width: 1160px;
  }

  .close-button {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 24px;
    height: 24px;
    border: none;
    border: 1px solid $colorMedium;
    border-radius: 50%;
    background: $colorShade;
    transition: transform 0.2s ease;
    transform: scale(1);

    &:hover {
      transition: transform 0.2s ease;
      transform: scale(1.1);

      &:before,
      &:after {
        background: $colorSecondary;
        transition: background-color 0.2s ease;
      }
    }

    &:focus {
      outline: none;
      border-color: darken($colorSecondary, 10%);

      &:before,
      &:after {
        background-color: darken($colorSecondary, 15%);
      }
    }

    &:before,
    &:after {
      position: absolute;
      top: 5px;
      left: calc(50% - 1px);
      width: 2px;
      height: 13px;
      background-color: $colorSecondary;
      content: "";
      transition: background-color 0.2s ease;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    min-height: 72px;
    border-radius: $radiusSmall $radiusSmall 0 0;
    background: rgba(0, 0, 0, 0.05);
  }

  .header-addon {
    display: flex;
    align-items: center;

    // adds left margin to every adjacent child starting from second
    > :nth-child(n + 2) {
      margin-left: 16px;
    }
  }

  .title {
    text-align: left;
    font-weight: $medium;
    font-size: 20px;
  }

  .content {
    overflow: auto;
    padding: 16px 16px 0 18px;

    &--no-footer {
      padding: 12px;
    }

    &--minimum-height {
      min-height: 300px;
    }
  }
}

// ModalFooter
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 16px 12px 12px;
  border-radius: 0 0 $radiusSmall $radiusSmall;

  > :nth-child(n + 2) {
    margin-left: 16px;
  }
}
