@import "../../theme/variables";
@import "../../theme/mixins";

.add-optional {
  align-items: center;
  background: transparent;
  border: 0;
  color: $colorSecondary;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  padding: 5px;
  transition: color 0.2s ease;

  svg {
    height: 8px;
    margin-right: 5px;
  }

  &--active {
    position: relative;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      content: "";
      height: 1px;
      background: $colorSecondary;
    }
  }

  &:focus {
    outline: none;
  }
}

.attachments {
  margin-bottom: 16px;
}

.button {
  &--remove {
    fill: $labelRemove;
  }

  @include for-size(phone-only) {
    margin-bottom: 20px;
    order: -1;
  }

  @include for-size(tablet-portrait-up) {
    margin-top: 20px;
  }
}

.input {
  @include for-size(tablet-landscape-max) {
    width: 100%;
  }
}

.item {
  margin: 15px 0;

  &-info {
    display: flex;
    flex-wrap: wrap;

    @include for-size(tablet-portrait-up) {
      .input {
        &--name {
          flex-grow: 1;
          max-width: 310px;
        }
      }

      .input {
        margin-right: 10px;
        width: 120px;
      }
    }
  }

  &-type {
    margin-bottom: 16px;
    width: 100%;
    order: 2;
  }

  &--portal {
    .item-type {
      margin-top: 18px;
      margin-right: 10px;
      order: initial;
      width: auto;
    }
  }
}

.optional-fields-area {
  margin-left: 13px;
  width: 100%;

  @include for-size(tablet-portrait-up) {
    width: 50%;
  }
}

.optionals {
  display: flex;
  flex-wrap: wrap;
}

.optional-field {
  align-items: center;
  display: flex;

  label {
    display: block;
    width: 100%;
  }

  button {
    margin-left: 10px;

    svg {
      fill: $colorError;
    }
  }
}

.fields {
  &-price {
    max-width: 210px;

    input {
      width: 100% !important;
    }
  }
}
