@import "../../theme/variables";

.activity-wrap {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "date info addons";
  margin-bottom: 18px;
  width: 100%;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
}

.date-area {
  display: flex;
  grid-area: date;
  min-width: 70px;
  color: $colorSecondary;
}

.info-area {
  display: flex;
  flex-direction: column;
  grid-area: info;
  margin-left: 24px;
}

.info-area-header {
  display: flex;
}

.info-area-body {
  display: flex;
  margin-top: 8px;
  line-height: 1.5;
}

.info-area-footer {
  display: flex;
}

.addons-area {
  display: flex;
  grid-area: addons;
  margin-left: 24px;
}
