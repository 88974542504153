@import "../../theme/variables";
@import "../../theme/mixins";

.view {
  @include scrollbar;

  display: flex;
  flex-basis: 100vh;
  flex-direction: column;
  height: 100vh;
}

.menu {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  min-height: 60px;
  background: $colorShade;
}

.content-wrap {
  overflow: auto;
  color: $colorInput;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 2rem;
  padding: 10px 10px 0;
  font-style: normal;
  font-family: Rubik;

  @include for-size(desktop-up) {
    padding: 20px 302px 20px 65px;
  }
}
