@import "../../theme/variables";

.approver {
  position: relative;
  display: flex;
  margin-bottom: 24px;
}

.container {
  flex-grow: 1;
  margin-left: 32px;
}

.side {
  position: absolute;
  line-height: 22px;
}

.row {
  display: flex;
  line-height: 22px;
}

.gap {
  margin-bottom: 4px;
}

.avatar {
  margin-left: 8px;
}

.status-icon {
  position: absolute;
  top: 50%;
  right: calc(100% + 16px);
  width: 16px;
  height: 16px;
  transform: translateY(-50%);

  fill: $colorMedium;

  &--approved {
    fill: $colorSuccess;
  }

  &--declined {
    fill: $colorError;
  }
}

.small-text {
  font-size: 12px;
}

.text-color-secondary {
  color: $colorSecondary;
}

.section {
  position: relative;
}

.separator {
  margin: 8px 0;
}
