@import "../../theme/variables";

.label {
  display: block;
  margin-bottom: 6px;
  text-transform: uppercase;
  font-weight: $medium;
  font-size: 12px;

  // fix for required and optional to be at same height
  &:after {
    content: "";
    font-size: 18px;
  }

  &--required {
    position: relative;

    &:after {
      padding-left: 3px;
      color: $colorError;
      content: "*";
      font-weight: $medium;
      font-size: 18px;
    }
  }
}

.content {
  margin-top: 8px;
  margin-bottom: 16px;
  text-transform: none;
  font-weight: normal;
  font-size: 16px;
}
