@import "../../theme/variables";

.avatar {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  // avoid the strange 3px bottom margin
  // https://stackoverflow.com/questions/10844205/html-5-strange-img-always-adds-3px-margin-at-bottom
  vertical-align: middle;

  &:hover {
    .initials,
    .image {
      opacity: 0.8;
    }
  }

  &--large {
    width: 40px;
    height: 40px;

    .initials {
      font-size: 16px;
    }
  }

  &--extra-large {
    width: 96px;
    height: 96px;
  }

  &--no-hover {
    &:hover {
      .initials,
      .image {
        opacity: 1;
      }
    }
  }

  &--with-text {
    margin-left: 5px;
  }
}

.unassigned {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ffe3d7;
  color: #fa9e9f;
  font-weight: $medium;
  line-height: 15px; // odd line-height is needed for proper vertical centering
  transition: opacity 300ms ease;

  user-select: none;

  &--loading {
    opacity: 0;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  transition: opacity 300ms ease;

  &--loading {
    opacity: 0;
  }
}

.initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #e2cef4;
  font-weight: 500;
  font-size: 9px;
  transition: opacity 300ms ease;

  user-select: none;

  &--loading {
    opacity: 0;
  }
}

// this is always rendered but with zero opacity when not visible
.loading-wrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms ease;

  &--visible {
    opacity: 1;
  }
}
