@import "../../theme/variables";

.accordion-container {
  width: 100%;
}

.accordion-header {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 34px;
  border-radius: 4px;
  background-color: $colorItem6;
  cursor: pointer;

  &--open {
    background-color: $cardColorReceivingCorrespondenceBarHeader;
  }
}

.header-arrow {
  display: flex;
  align-items: center;
  width: 12px;
  height: 100%;

  & svg {
    width: 100%;
    fill: $subtitleColor;
  }
}

.header-title {
  flex: 1 1 auto;
  margin: 0 8px;
  font-weight: 500;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
}

.header-label {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  padding: 8px;
  min-width: 20px;
  height: 22px;
  border-radius: 11px;
  background-color: $statusAlertBackground;
  color: $colorLight;
  font-weight: 700;
  font-size: 12px;
}

.accordion-body {
  display: flex;
  flex-direction: column;
}

_::-webkit-full-page-media,
_:future,
:root .accordion-body {
  display: block !important;
}
