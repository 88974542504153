@import "../../theme/variables";

.wrap {
  font-size: 14px;
  font-family: "Rubik", sans-serif;
}

.row {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 2px;
  margin-bottom: 2px;
  width: 514px;
}

.supplier {
  display: grid;
  grid-template-columns: auto 9fr 5fr;
  grid-column-gap: 8px;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background-color: #f2f0eb;
  color: $colorInput;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $colorLight;

    &--hidden {
      visibility: hidden;
      width: 8px;
    }
  }

  .name-container {
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  .name-text {
    overflow: hidden;
    max-width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: $medium;
  }

  .email-container {
    display: flex;
    align-items: center;
  }

  .email-text {
    overflow: hidden;
    max-width: 130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 300;
    font-size: 15px;
    opacity: 0.6;
  }
}

.actions {
  display: flex;
  padding: 8px;
  width: 46px;
  border-radius: 4px;
  background-color: #f2f0eb;
  color: $colorSuccess;
  font-weight: $medium;
  font-size: 12px;
}

.button-icon {
  fill: $colorSuccess;
}
