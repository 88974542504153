@import "../../theme/variables";

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $fieldMargin;
  min-height: 120px;
  border: 1px dashed $colorMedium;
  border-radius: $radiusSmall;
  background: rgba(255, 255, 255, 0.5);
  color: $colorSecondary;
  text-align: center;
  cursor: pointer;

  &--short {
    width: $shortField;
  }

  &--active,
  &--selected {
    border-color: $colorSuccess;
    color: $colorSuccess;
  }

  &--rejected {
    border-color: $colorInfo;
  }

  &--loading {
    // FIXME
    border-color: $colorMedium;
  }

  &--with-error {
    border-color: $colorError;
  }
}

.error {
  color: $colorError;
}

.warning {
  color: $colorInfo;
}
