@import "../../theme/variables";

.wrap {
  display: flex;
  min-width: 100px;
  height: 33px;
  border: 1px solid $formFieldOptional;
  border-radius: 4px;
  background-color: $colorItem7;
}
