@import "../../theme/variables";
@import "../../theme/mixins";

.wrap {
  display: flex;
  color: $colorInput;
  font-weight: 400;
  font-size: 11px;
  font-family: "Rubik" sans-serif;
}

.radio-label {
  margin-right: 0;
  margin-left: 0;
  padding: 8px 16px;
  background-color: white;
  cursor: pointer;

  &:first-child {
    border: 1px solid $fieldBorder;
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border: 1px solid $fieldBorder;
    border-radius: 0 4px 4px 0;
  }

  &:not(:first-child):not(:last-child) {
    border: 1px solid $fieldBorder;
  }

  &:only-child {
    border: 1px solid $fieldBorder;
    border-radius: 4px;
  }

  &--withIcon {
    align-items: center;
    display: flex;
    padding: 8px;

    svg {
      fill: $fieldBorder;
      height: 12px;
      margin-right: 5px;
      order: -1;
    }
  }

  &--large {
    font-size: 15px;

    svg {
      height: 16px;
    }
  }

  &--active {
    border: 1px solid $colorSuccess !important;
    color: $colorSuccess;

    svg {
      fill: $colorSuccess;
    }
  }

  &--disabled {
    color: $colorSecondary;
    opacity: 0.6;
  }

  &:hover:enabled {
    background-color: $colorHover;
  }

  &:focus:enabled,
  &:active:enabled {
    @include background-opacity($colorSuccess, 0.25);
  }
}

.radio-input {
  display: none;
}
