@import "../../theme/variables";
@import "../../theme/mixins";

.attachments {
  margin-top: 1rem;

  &-url {
    display: flex;
    align-items: baseline;
    color: $colorSuccess;
    font-weight: $medium;
    font-size: 14px;
  }
}

.filename {
  overflow: hidden;
  max-width: 270px;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include for-size(tablet-landscape-up) {
    max-width: 400px;
  }
}

.open-external-icon {
  margin-left: 4px;
  min-width: 11px;
  width: 11px;

  fill: $colorSuccess;
}
