@import "../../theme/variables.scss";
@import "../../theme/mixins";

.swimlane {
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  min-width: 270px;
  width: 100%;

  &--large {
    min-width: 352px;
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 15px;
  border-top-left-radius: $radiusLarge;
  border-top-right-radius: $radiusLarge;
}

.title {
  margin: 0;
  width: inherit;
  font-size: 15px;
}

.content-wrapper {
  position: relative;
  padding: 8px 4px 8px 8px;
  min-height: 100px;
  max-height: calc(100% - 40px);
  border-bottom-right-radius: $radiusLarge;
  border-bottom-left-radius: $radiusLarge;
}

.content {
  overflow-y: scroll; //TODO: Research hot to get rid of scrollbar appearing and then disappearing when a card is dragged to the new swimlane. Ideally should be able to use overflow: auto
  max-height: 100%;
}

.empty-swimlane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  padding: 0 10px;
  height: 140px;
  border-radius: $radiusSmall;
  background: white;
  color: $colorSecondary;
  text-align: center;
  font-style: italic;
  font-size: 15px;
  line-height: 1.5em;
  opacity: 1;
}

.create-new-card {
  position: absolute;
  top: -32px;
  right: 16px;
  left: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: $radiusSmall;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 15px;
  line-height: 1.5em;
  opacity: 0;
  transition: opacity 300ms ease;

  // this element has to be always displayed but we can hide it with opacity when not needed
  &--visible {
    opacity: 1;
  }

  &--over {
    background-color: rgb(219, 255, 219);
  }
}

// TODO: could this be refactored as <Button data-testid="7b78386125" add .../>?
.add-button {
  $plusIconSize: 14px;

  position: absolute;
  right: 10px;
  width: 22px;
  height: 22px;
  border: none;
  border-radius: $radiusSmall;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:focus {
    outline: none;
    border: 1px solid $colorSuccess;
  }

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #5ebe3f;
    content: "";
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 2px;
    height: $plusIconSize;
  }

  &:after {
    width: $plusIconSize;
    height: 2px;
  }

  &--footer {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 9px;
    width: calc(100% - 5px);
    height: 30px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    transition: background-color 0.2s ease;
    transform: none;

    &:hover {
      background-color: #f0f8f3;
    }
  }
}

.header-loading-icon {
  position: absolute;
  right: 12px;

  // move the icon further back when the add button is also used
  &--with-add {
    right: 42px;
  }
}

.swimlanes {
  @include scrollbar;

  position: relative;
  flex-grow: 1;

  &__inner-wrapper {
    position: absolute;
    top: 0;
    right: 5px; // adds extra space on the right side when horizontal scroll is visible
    bottom: 0;
    left: 0;
    display: flex;
    overflow: auto;
    padding: 20px 0 10px 5px;

    // TODO: react-dnd does not work with nested scrollable containers, remove once working
    // https://github.com/atlassian/react-beautiful-dnd/issues/131
    @media only screen and (min-width: 1500px) {
      overflow: hidden;
    }
  }
}
