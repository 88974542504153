@import "../../theme/variables";

.wrap {
  display: flex;
  width: 100%;
}

.link-sent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  padding: 48px;
  border-radius: 16px;
  background-color: rgba(0, 191, 58, 0.3);

  &-title {
    margin-bottom: 12px;
    color: $labelDefault;
    font-size: 12px;
  }

  &-text {
    color: $colorTextContrast;
    font-weight: 500;
    font-size: 16px;
  }
}

.many-suppliers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  padding: 48px;
  border-radius: 16px;
  background-color: $statusPink;

  &-title {
    margin-bottom: 12px;
    color: $labelDefault;
    font-size: 12px;
  }

  &-text {
    color: $colorTextContrast;
    font-weight: 500;
    font-size: 16px;
  }
}
