.version {
  position: absolute;
  bottom: 1px;
  left: 1px;
  color: #eeeeee;
  font-size: 10px;
  transition: color ease-in-out 500ms;

  &:hover {
    color: #333333;
  }
}
