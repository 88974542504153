@import "../../theme/variables";

// Export colors from variables for React component to import and use
:export {
  labelAlert: $labelAlert;
  labelSuccess: $labelSuccess;
  labelWarning: $labelWarning;
}

// The --borderColor is actually set in StatusNotice.tsx
$borderColor: var(--borderColor);

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 8px;
  max-width: 100%;
  max-height: calc(100vh - 56px); // 100vh minus top and bottom margin
  border-radius: $radiusSmall;
  background: $colorItem5;
  color: $colorInput;

  &::before {
    position: absolute;
    top: 1px;
    left: 50%;
    width: calc(100% - 2px);
    border-top: 4px solid var(--borderColor);
    border-radius: $radiusSmall $radiusSmall 0 0;
    content: "";
    transform: translate(-50%);
  }
}

.content-wrap {
  display: flex;
  margin: 16px 12px 16px 12px;
  font-family: "Rubik", sans-serif;
}

.avatar {
  flex: 0 0 22px;
}

.event-container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.event-meta {
  display: flex;
  margin: 2px 0 0 0;
}

.event-title {
  font-weight: $medium;
  font-size: 14px;
}

.event-description {
  margin: 12px 0 0 0;
  font-weight: 400;
  font-size: 12px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 4px;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  transition: transform 0.2s ease;
  transform: scale(1);

  &:hover {
    transition: transform 0.2s ease;
    transform: scale(1.1);

    &:before,
    &:after {
      background-color: darken($colorSecondary, 15%);
    }
  }

  &:focus {
    outline: none;
    border-color: darken($colorSecondary, 10%);

    &:before,
    &:after {
      background-color: darken($colorSecondary, 25%);
    }
  }

  &:before,
  &:after {
    position: absolute;
    top: 5px;
    left: calc(50% - 1px);
    width: 2px;
    height: 13px;
    background-color: $colorSecondary;
    content: "";
    transition: background-color 0.2s ease;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
}
