@import "../../theme/variables";
@import "../../../src/theme/mixins";

.modal {
  background: $colorItem1;
}

.wrap {
  margin: -16px -16px 0 0;
}

.label-dropdown {
  align-self: center;
}

.addon-wrap {
  display: flex;
  gap: $fieldMargin;
  position: relative;
}

.purchase-order-container {
  display: grid;
  grid-template-columns: minmax(auto, 1fr) minmax(0, auto);
  grid-template-areas: "left right";
  min-height: 600px;
}

.left-container {
  grid-area: left;
  margin-right: 8px;
  padding-right: 12px;
}

.right-container {
  overflow-x: hidden;
  grid-area: right;
  border-left: 1px solid rgba(0, 0, 0, 0.085);
}

.purchase-order-form {
  padding-top: 30px;
}

.avatar {
  margin-right: 16px;
}

.add-button-cell {
  padding: 10px 0;
}

.add-button {
  position: relative;
  margin: 12px 0 0 16px;
  color: $colorSecondary;

  &:before,
  &:after {
    position: absolute;
    left: -12px;
    display: block;
    width: 8px;
    height: 2px;
    background: $colorSuccess;
    content: "";
  }

  &:after {
    transform: rotate(90deg);
  }
}

.close-button {
  position: relative;
  margin: 12px 0 0 16px;
  color: $colorSecondary;

  &:before {
    position: absolute;
    left: -12px;
    display: block;
    width: 8px;
    height: 2px;
    background: $colorError;
    content: "";
  }
}

.order-number-container {
  display: flex;
  align-content: center;
  margin: 0 0 24px 0;
}

.order-number-title {
  display: flex;
  align-items: center;
  margin: 0 12px 0 0;
  font-weight: $medium;
  font-size: 20px;
  font-family: "Rubik", sans-serif;
}

.order-number-input {
  margin: 0 12px 0 0;
  width: 150px;
}

.order-dates-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 44px 0;

  & > * {
    margin-right: 32px;
  }
}

.order-supplier-container {
  margin: 26px 0 0 0;
}

.order-supplier-notes-input {
  margin: 26px 0 0 0;
}

.delivery-info-container {
  margin: 26px 0 0 0;
}

.delivery-info-new-address {
  margin: 26px 0 0 0;
  padding: 40px;
  width: 50%;
  background-color: $colorHover;
}

.delivery-info-new-address-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  width: 100%;

  > :nth-child(n + 2) {
    margin-left: 16px;
  }
}

.delivery-info-notes {
  margin: 26px 0 48px 0;
}

.payment-info-container {
  margin: 26px 0 0 0;
}

.payment-info-notes {
  margin: 38px 0 36px 0;
}

.payment-terms-label {
  position: relative;
  margin: 24px 0 8px 0;
  text-transform: uppercase;
  font-weight: $medium;
  font-size: 12px;
  font-family: "Rubik", sans-serif;

  &:after {
    position: absolute;
    padding-left: 4px;
    color: #f55c21;
    content: "*";
    font-weight: 500;
  }
}

.payment-terms-container {
  display: flex;
  align-content: center;
  margin: 0 0 24px 0;
}

.payment-terms-input {
  margin: 0 12px 0 0;
}

.payment-info-currency-select {
  margin: 0 0 24px 0;
}

.payment-info-button {
  margin-bottom: 38px;
}

.align-left {
  margin-right: auto;
}

.block-required {
  position: relative;

  &:after {
    position: absolute;
    padding-left: 4px;
    color: #f55c21;
    content: "*";
    font-weight: 500;
  }
}

.send-to-supplier-wrap {
  margin: 0 0 32px 32px;
}

// Releasing option form
.releasing-options-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.releasing-options-form-header {
  display: inline-flex;
  margin: 0 0 18px 0;
  padding: 12px;
  width: fit-content;
  background-color: #f6f1e5;
}

.releasing-options-form-header-icon {
  margin-right: 14px;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
}

.releasing-options-form-header-title {
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  font-family: Rubik;
  line-height: 21px;
}

.releasing-options-select-container {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.releasing-options-select {
  margin: 0;
}

.releasing-options-clear {
  margin-left: 8px;
  min-width: 14px;
  min-height: 14px;
  cursor: pointer;

  &:hover {
    transition: transform 300ms;
    transform: scale(1.5);
  }
}

.releasing-options-remove-icon {
  width: 14px;
  fill: $colorError;
}

// E-mail related classes

.emailFormWrapper {
  margin: 16px 0;
}

.fieldLabel {
  min-width: 80px;
  font-weight: bold;
}

.address-country-select {
  width: 100%;
}

.po-pdf-attachment {
  margin-bottom: 8px;
  cursor: pointer;
}

.po-pdf-link {
  margin: 12px 0 0 62px;
  color: $colorSecondary;
  font-weight: 500;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  cursor: pointer;
}

.open-external-icon {
  margin-left: 4px;
  min-width: 11px;
  width: 11px;
  fill: $colorSuccess;
}

.emailAddress {
  margin: 4px 4px 4px 0;
  padding: 4px;
  border-radius: $radiusLarge;
  background-color: $statusSuccess;
}

.note {
  display: flex;
}

.emailNote {
  margin: 0 0 8px 8px;
  font-weight: lighter;
}

.emailFormHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f5f1e4;
  font-weight: bold;
  cursor: pointer;
}

.emailFormTitle {
  display: flex;
  align-items: center;
}

.oneToManyIcon {
  width: 48px;
  height: 24px;
}

.emailFormBody {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #f1eae1;
}

.emailFrom,
.emailTo,
.ccField,
.subjectField,
.messageField {
  display: flex;
  align-items: center;
  margin: 8px;
}

.emailTo {
  flex-wrap: wrap;
}

.ccInput,
.subjectInput {
  margin: 0;
}

.attachments {
  align-items: baseline;
  margin-bottom: 24px;
  margin-left: 88px;
  color: $colorSecondary;
  font-weight: $medium;
  font-size: 14px;
}

.delete-file-icon {
  margin-right: 4px;
  width: 11px;
  height: 11px;
  fill: $colorError;
  cursor: pointer;
}

.request-status {
  margin-right: auto;
}

.button-wrap {
  display: flex;
  margin-left: 88px;
  width: calc(50% - 12px);
}

.email-sent {
  display: flex;
  align-items: center;
  margin: auto;
  padding: 12px;
  width: fit-content;
  background-color: white;
}

.email-sent-checkmark-container {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 66px;
  height: 66px;
  border-radius: 33px;
  background-color: $colorSuccess;
}

.email-sent-checkmark-icon {
  flex: 0 0 auto;
  height: 48px;
  fill: $colorLight;
}

.email-sent-text {
  flex: 0 0 auto;
  color: $colorTextContrast;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;
  font-family: Rubik;
  line-height: 36px;
}

.email-sent-flex-space {
  flex: 1 1 200px;
  min-width: 200px;
}

.email-send-to-supplier-button {
  display: flex;
}

.email-send-to-supplier-button-icon {
  margin-right: 4px;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  fill: $colorLight;
}

.archive-icon {
  fill: $labelRemove;
}
