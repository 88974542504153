@import "../../../../theme/variables";

.wrap {
  display: flex;
  align-items: center;
}

.input {
  composes: input from "../../../Field/Field.module.scss";
}

.search-icon {
  margin: 0 8px 0 16px;
  width: 16px;
  height: 16px;
  fill: $colorInput;
}

.filter-stats {
  margin-left: 8px;
  font-size: 12px;

  .current-row-count {
    font-weight: 500;
  }

  .total-row-count {
    font-weight: 400;
  }
}

.filter-archive {
  margin: 0;
}