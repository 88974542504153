@import "../../theme/variables";

.input {
  composes: input from "../Field/Field.module.scss";
}

.search-wrap {
  display: flex;
  border-radius: 8px;
  border: 1px solid $colorMedium;
  background: white;
  flex-basis: 25%;
  margin-right: 8px;
}

.search-icon {
  margin: 8px;
  width: 16px;
  height: 16px;
  fill: $colorInput;
}

.search input {
  padding: 8px 4px 8px 0;
  border: 0;
  border-radius: 0 8px 8px 0;

  &:active,
  &:focus {
    border-color: $colorSuccess;
    transition: all 200ms ease;
  }

  // removes default behavior
  &:focus {
    outline: none;
  }
}

.addon {
  display: inline-flex;
  align-items: center;
  padding-left: 4px;
  margin-right: 8px;
  height: 16px;

  &--not-found {
    color: $colorError;
    font-size: 13px;
  }

  .addon-icon {
    margin-left: 2px;
    width: 12px;
    height: 12px;

    fill: $colorError;
  }
}

.suggestion {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding: 8px 16px 8px 8px;
  color: $colorInput;
  font-weight: $medium;
  cursor: pointer;

  &:hover,
  &--highlight {
    background: $colorHover;
  }

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
  }

  .email {
    padding: 4px 0;
    font-size: 12px;
  }

  .text-highlight {
    font-weight: normal;
  }

  .code {
    small {
      font-size: 70%;
    }
  }

  .status {
    &--pr {
      background: $laneColorPrHeader;
    }

    &--rfx {
      background: $laneColorRfxHeader;
    }

    &--po {
      background: $laneColorOrderHeader;
    }

    &--po-receiving {
      background: $cardColorReceivingHeader;
    }

    &--po-invoice {
      background: $laneColorInvoicesHeader;
    }

    &--margin {
      margin-right: 4px;
    }
  }
}
