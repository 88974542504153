@import "../../theme/variables";

.wrap {
  &--wide {
    width: 100%;
  }
}

.autosuggest {
  display: flex;
  margin-bottom: $fieldMargin;

  &:only-child {
    margin-bottom: 0;
  }

  &--short {
    width: $shortField;
  }
}

.input {
  padding: 7px;
  width: 100%;
  height: $addonHeight;
  border: 1px solid $colorMedium;
  border-radius: $radiusSmall;
  color: $colorInput;
  font-size: 15px;
  transition: all 200ms ease;

  &:hover {
    border-color: $colorSecondary;
    transition: all 200ms ease;
  }

  &:active,
  &:focus {
    border-color: $colorSuccess;
    transition: all 200ms ease;
  }

  // removes default behavior
  &:focus {
    outline: none;
  }
}

.information {
  width: 100%;
}
.left-column {
  display: inline-block;
  margin-bottom: 16px;
  width: 300px;
  font-weight: bold;
}
.right-column {
  display: inline-block;
  margin-bottom: 16px;
  width: calc(100% - 300px);
}

.error {
  margin-top: 8px;
  color: $colorError;
  font-size: 14px;
}

.content {
  // content is wrapped in a relative wrap to position addon correctly regardless of whether label is used or not
  position: relative;
  width: 100%;
}

.addon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 8px;
  height: $addonHeight;

  &--not-found {
    color: $colorError;
    font-size: 13px;
  }

  .addon-icon {
    margin-left: 2px;
    width: 12px;
    height: 12px;

    fill: $colorError;
  }
}

.suggestions {
  position: absolute;
  top: 36px;
  z-index: 2;
  overflow-y: auto;
  max-height: 200px;
  width: 100%;
  border-radius: $radiusSmall;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  &--not-found {
    padding: 8px;
  }

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.clear {
  display: inline-flex;
  padding: 8px;
  color: $colorSuccess;
  text-transform: uppercase;
  font-weight: $medium;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
