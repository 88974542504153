@import "../../theme/variables";
@import "../../theme/mixins";

.wrap {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "avatar info"
    "guideline body"
    "reply reply";
  align-items: center;
  max-width: 800px;
  width: 100%;
  font-family: "Rubik", sans-serif;

  @include for-size(tablet-portrait-up) {
    grid-template-columns: auto minmax(1fr, 1fr) minmax(min-content, 120px) 32px;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "avatar info time-ago toggle"
      "guideline body empty empty"
      "reply reply reply reply";
  }
}

.avatar {
  display: flex;
  grid-area: avatar;
  justify-content: center;
  cursor: pointer;
}

.info-container {
  grid-area: info;
  margin-left: 12px;
  font-weight: 600;
  cursor: pointer;

  &--hasBeenRead {
    font-weight: 400;
  }
}

.info-details {
  display: flex;
  color: $colorInput;
  font-size: 12px;
  line-height: 14px;
}

.from-address {
  font-size: 14px;
  line-height: 14px;

  &--narrow {
    font-weight: 500;
  }
}

.from-subject {
  overflow: hidden;
  margin-left: 4px;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 14px;
}

.has-attachments {
  margin-left: 8px;
  height: 12px;
}

.info-sent-time {
  margin-bottom: 2px;
  color: $subtitleColor;
  font-size: 10px;
  line-height: 12px;
}

.created-time-ago {
  display: none;
  font-weight: 600;
  cursor: pointer;

  @include for-size(tablet-portrait-up) {
    display: block;
    grid-area: time-ago;
    justify-self: flex-end;
    align-self: flex-start;
    margin-top: 4px;
    color: $subtitleColor;
    font-style: italic;
    font-size: 10px;
    line-height: 12px;
  }

  &--hasBeenRead {
    font-weight: 400;
  }
}

.toggle-cell {
  display: none;

  @include for-size(tablet-portrait-up) {
    display: block;
    grid-area: toggle;
    align-self: flex-start;
    margin-top: 8px;
  }
}

.toggle-button {
  padding: 0;
  height: 6px;
  border: none;
  background: transparent;
  fill: $subtitleColor;
  box-shadow: none;
  opacity: 0.55;

  &:hover {
    border: none;
    box-shadow: none;
  }

  &:active,
  &:focus {
    border: none;
    box-shadow: none;
  }
}

.guideline {
  grid-area: guideline;
  justify-self: center;
  margin-top: 8px;
  width: 4px;
  height: calc(100% - 8px);
  border-radius: 2px;
  background-color: $labelWarning;
}

.body {
  display: flex;
  flex-direction: column;
  grid-area: body;
  margin: 8px 0 0 12px;
}

.cc-container {
  display: flex;
  margin-bottom: 4px;
  font-size: 10px;
}

.subject-container {
  display: flex;
  font-size: 12px;
}

.field-title {
  width: 48px;
  font-weight: 500;
}

.field-value {
  margin-left: 4px;
  font-weight: 400;
}

.body-text {
  margin: 8px 0 8px 0;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 130%;
}

.attachments-list {
  display: flex;
  margin-bottom: 8px;
  color: $subtitleColor;
  font-size: 10px;
}

.open-external-icon {
  margin-left: 4px;
  min-height: 10px;
  height: 10px;
  fill: $colorSuccess;
}

.email-actions {
  display: inline-flex;
  color: $colorSuccess;
  letter-spacing: 0.436px;
  font-weight: bold;
  font-size: 12px;
  padding-right: 12px;
}

.reply-form {
  grid-area: reply;
  margin-top: 8px;
}
