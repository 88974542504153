@import "../../theme/variables";
@import "../../theme/mixins";

@media print {
  .printable-overlay {
    position: static;
  }

  .printable {
    max-height: unset !important;

    tr td {
      -webkit-column-break-inside: avoid !important;
      page-break-inside: avoid !important;
      break-inside: avoid !important;
    }

    tr td:first-child {
      -webkit-column-break-before: auto !important;
      page-break-before: auto !important;
      break-before: auto !important;
    }

    tr td:first-child div:first-child {
      position: relative !important;
    }
  }
}

.wrap {
  min-height: 400px;
}

.label-dropdown {
  align-self: center;
}

.addon-wrap {
  display: flex;
  gap: $fieldMargin;
  position: relative;
}

.purchase-request-container {
  display: grid;
  grid-template-columns: minmax(auto, 1fr) minmax(0, auto);
  grid-template-areas: "left right";
  min-height: 600px;
}

.left-container {
  grid-area: left;
  margin-right: 8px;
  padding-right: 12px;
}

.right-container {
  overflow-x: hidden;
  grid-area: right;
  border-left: 1px solid rgba(0, 0, 0, 0.085);
}

.add-new-approver {
  margin: 0 0 16px 0;
  padding: 8px;
  border-radius: 4px;
  background-color: $colorHover;
}

.add-approver-button {
  margin-bottom: 16px;
}

.names {
  display: flex;
  padding-top: 12px;
}

.first-name {
  margin-right: 8px;
}

.email {
  width: calc(min(100%, 375px));
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 0 8px 16px 0;
}

.cancel-button {
  margin-right: 16px;
}

.approver {
  width: 504px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.approver-section {
  background: $colorShade;

  .cell {
    border-radius: 0;
    vertical-align: top;
  }
}

tbody.approver-section {
  tr:first-child {
    .cell:first-child {
      border-top-left-radius: $radiusSmall;
    }

    .cell:last-child {
      border-top-right-radius: $radiusSmall;
    }
  }
}

tfoot.approver-section {
  tr {
    .cell {
      border-bottom-right-radius: $radiusSmall;
      border-bottom-left-radius: $radiusSmall;
    }
  }
}

.datepicker {
  margin-bottom: 0;
}

.autosuggest-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .autosuggest {
    flex-grow: 1;
    margin: 0 8px 0 32px;
  }
}

.justifiedCols {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.margin-left {
  margin-left: 32px;
}

.notificationsField {
  margin-right: 32px;

  &--highlight {
    font-weight: bold;
  }
}

.statusBadge {
  text-align: right;
  font-weight: $medium;

  .approved-icon {
    fill: $colorSuccess;
  }

  .close-icon {
    fill: $colorError;
  }
}

.ready-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  fill: white;
}

.archive-icon {
  fill: $labelRemove;
}

.attachment-list {
  margin-bottom: 16px;

  &--is-not-editable {
    display: flex;
  }

  .attachment-list--left-column {
    display: inline-block;
    width: 300px;
    font-weight: bold;
  }
  .attachment-list--right-column {
    display: inline-block;
    width: calc(100% - 300px);
  }
}

.url-attachment {
  display: flex;
  align-items: center;

  &-link {
    display: flex;
    align-items: baseline;
    color: $colorSuccess;
    font-weight: $medium;
    font-size: 14px;
  }
}

.file-list {
  margin-bottom: 16px;
}

.filename {
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-file-icon {
  margin-right: 8px;
  width: 11px;
  height: 11px;
  fill: $colorError;
  cursor: pointer;
}

.flex-grow-1 {
  flex-grow: 1;
}

.row-flex {
  display: flex;
  flex-direction: row;
}

.cell-flex {
  display: flex;
  flex-basis: content;
  flex-direction: column;
  justify-content: space-between;
}

.align-left {
  margin-right: auto;
}

.field-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &--non-editable {
    flex-direction: column;
  }
}

.stop-approval-workflow-link {
  color: $colorSecondary;
  text-decoration: underline;
  font-weight: normal;
  font-style: normal;
  font-family: Rubik;
}

.approval-workflow-cancelling-reason-form {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px 24px;
  width: 50%;
}

.cancelling-reason-button {
  align-self: flex-end;
  width: 120px;
}

.custom-fields-list {
  margin-bottom: 16px;
}
