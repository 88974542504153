@import "../../theme/mixins";

.content {
  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &__title {
      font-size: 1.6em;
      margin-bottom: 0;
    }

    @include for-size(tablet-portrait-max) {
      display: block;
      margin-right: 5px;

      button {
        margin: auto;
        margin-right: 0;
      }

      &__title {
        display: none;
      }
    }
  }
}
