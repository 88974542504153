@import "../../theme/variables";

.edit {
  font-weight: bold;
  font-size: 15px;
}

.highlight {
  font-weight: $medium;
}

.whitespace-right {
  margin-right: 5px;
}

.file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 16px;
}

.title {
  margin-bottom: 8px;
}

.attachment {
  display: flex;
  align-items: baseline;
  color: $colorSuccess;
  font-weight: $medium;
  font-size: 14px;
}

.attachment-date {
  color: $colorSecondary;
  text-align: right;
  font-size: 12px;
}

.date {
  margin-left: 16px;
  color: $colorSecondary;
  font-size: 14px;
}

.author {
  margin-bottom: 0;
  min-width: 220px;
}

.open-external-icon {
  margin-left: 4px;
  min-width: 11px;
  width: 11px;
  fill: $colorSuccess;
}

.filename {
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.section-wrap {
  display: flex;
  justify-content: space-between;
}

.contacts {
  margin-right: 32px;
}

.side-section {
  flex-grow: 1;
}

.container {
  &--stretched {
    line-height: 23px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// contracts and documents upload
.uploader-wrapper {
  display: flex;
  align-items: center;
}

.uploader-data {
  margin-left: 8px;
  font-size: 12px;
}

.uploader-name {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 2px;
  color: $colorSuccess;
  font-weight: $medium;
}

.upload-date {
  color: $colorSecondary;
}

.default-contact {
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    height: 16px;
  }
}

.supplier-categories-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 343px;

  & .chip {
    margin-bottom: 4px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: $colorItem4;
    color: white;
  }
}
