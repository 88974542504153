@import "../../theme/variables";

$labelTextColor: var(--labelTextColor);

.dropdown-content {
  overflow: auto;
  max-width: 280px;
  max-height: 280px;
  animation: none;
}

.dropdown-selector {
  border-radius: 50%;

  .button {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .button-icon {
    width: 15px;
    fill: $colorSuccess;
  }

  &:focus {
    box-shadow: 0 0 0 1px $colorSuccess;
  }

  &--disabled {
    cursor: auto;

    &:focus {
      box-shadow: none;
    }
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.dropdown-button-container {
  padding: 0 0 0 4px;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 32px;
  outline: none;
  border: none;
  border-radius: $radiusSmall;
  text-align: center;
  white-space: nowrap;
  font-weight: $medium;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.4s, border-color 0.4s;
}

.dropdown-selector-button {
  padding: 6px;
  min-width: 32px;
  height: 32px;
  border: 1px solid #ffffff;
  background: #ffffff;
  margin: 0px 4px 4px 0;
  width: 20px;

  &:focus {
    border: 1px solid $colorSuccess;
    box-shadow: none;
  }

  &:hover {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  }

  .button-icon {
    width: 100%;
    height: 15px;
    fill: $colorSuccess;
  }
}
