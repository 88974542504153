@import "../../theme/variables";
@import "../../theme/mixins";

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 10px 0;
  margin-bottom: 2rem;

  @include for-size(desktop-up) {
    padding: 20px 25px;
  }
}

.link {
  @include for-size(tablet-landscape-up) {
    margin-right: auto;
  }

  &:hover {
    text-decoration: none;
  }
}

.link-content {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
  background-color: $colorAliceBlueDark;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #000000;
  font-weight: bold;

  @include for-size(tablet-landscape-up) {
    display: inline-flex;
  }

  .arrow {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
  }
}

.body {
  flex-grow: 1; // make body fill maximum space
  padding: 8px;
  background-color: $colorAliceBlueLight;

  @include for-size(tablet-landscape-up) {
    padding: 32px 32px 32px 80px;
    max-width: 1200px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .body-section-title {
    margin-right: 16px;
    color: #000000;
    text-transform: uppercase;
  }

  .request-info {
    margin-bottom: 8px;
    font-size: 15px;
  }
}

.identifier {
  display: inline-block;
  margin-right: 8px;
  font-weight: bold;
}

.last-row > td {
  padding-bottom: 16px !important;
}

.request-statuses {
  margin-top: 1rem;
}

.request-sessions {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;

  @include for-size(desktop-up) {
    .request-section:nth-child(1) {
      order: 2;
    }

    .request-section:nth-child(2) {
      order: 1;
    }
  }
}

.request-section {
  font-size: 0.85rem;
  width: 100%;

  &-title {
    text-transform: uppercase;
  }

  &-value,
  &-link {
    font-weight: bold;
  }

  @include for-size(desktop-up) {
    font-size: 1rem;
    width: 45%;

    &-title {
      margin-top: 1rem;
    }
  }
}
