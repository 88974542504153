@import "../../theme/variables";

.wrap {
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 6px;
  padding: 4px;
  border-radius: 4px;
  background-color: $laneColorOrderBody;
  font-weight: 400;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
}

.remove-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 4px;
  padding-top: 2px;
  width: 10px;
  height: 10px;
  background: transparent;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 8px;
    height: 1px;
    background: $labelRemove;
    content: "";
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:hover,
  &:focus {
    transform: scale(1.4);
  }

  &:active {
    transform: scale(1);
  }
}

.divider {
  margin-left: 8px;
  color: white;
}
