@import "../../theme/variables";
@import "../../theme/mixins";

.actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  @include for-size(tablet-portrait-up) {
    min-width: 80px;
  }
}

.container {
  background-color: $colorItem5;
  border-radius: 8px;
  padding: 12px 12px 30px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &::after {
    background-color: $labelDefault;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .badge {
    align-items: center;
    background-color: $labelDefault;
    border-radius: $radiusSmall;
    color: white;
    display: inline-flex;
    font-size: 11px;
    font-weight: $medium;
    padding: 4px 4px 2px;
    position: absolute;
    right: 12px;
    top: 12px;

    @include for-size(phone-only) {
      display: none;
    }
  }

  &.status {
    &--alert,
    &--warning {
      .badge {
        background-color: $labelAlert;
      }

      &::after {
        background-color: $labelAlert;
      }

      .details-view-link {
        fill: $labelAlert;
      }
    }

    &--default {
      .badge {
        background-color: $labelDefault;
      }

      &::after {
        background-color: $labelDefault;
      }

      .details-view-link {
        fill: $labelDefault;
      }
    }

    &--info {
      .badge {
        background-color: $labelInfo;
      }

      &::after {
        background-color: $labelInfo;
      }

      .details-view-link {
        fill: $labelInfo;
      }
    }

    &--success {
      .badge {
        background-color: $labelSuccess;
      }

      &::after {
        background-color: $labelSuccess;
      }

      .details-view-link {
        fill: $labelSuccess;
      }
    }

    &--withLink {
      cursor: pointer;
    }
  }

  @include for-size(tablet-portrait-up) {
    padding: 22px;
  }

  &--secondary {
    @include for-size(phone-only) {
      background-color: transparent;
      padding: 0;
    }
  }
}

.details-view-link {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);

  &--secondary {
    display: none;
  }
}

.summary-header {
  display: flex;
}
