@import "../../theme/_variables";

.wrap {
  display: flex;
  flex-direction: column;
  color: $colorInput;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
}

.emailFrom,
.emailTo {
  margin-bottom: 8px;
}
.emailTo {
  position: relative;
}
.cc-toggle-container {
  position: absolute;
  top: 0;
  right: 0;
}

.subjectInput {
  margin-bottom: 8px;
}

.emailFrom,
.emailTo,
.ccField,
.subjectField,
.messageField {
  display: flex;
  flex-direction: column;
}

.fieldLabel {
  margin-bottom: 6px;
  font-weight: 500;
}

.email-sender {
  font-weight: 400;
}

.emailAddresses {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  border: 1px solid $fieldBorder;
  border-radius: 4px;
  background-color: white;

  & > * {
    margin-bottom: 4px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.emailTo {
  flex-wrap: wrap;
}

.cc-toggle-container {
  display: flex;
  justify-content: flex-end;
}

.button-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  width: 100%;
}

.add-files {
  margin-right: auto;
}

.send-button {
  margin-left: 8px;
}

.add-button {
  position: relative;
  margin-right: 4px;
  color: $colorSecondary;
  font-weight: 400;
  font-size: 10px;

  &:before,
  &:after {
    position: absolute;
    left: -12px;
    display: block;
    width: 8px;
    height: 1px;
    background: $colorSuccess;
    content: "";
  }

  &:after {
    transform: rotate(90deg);
  }
}

.close-button {
  position: relative;
  margin-right: 4px;
  color: $colorSecondary;
  font-weight: 400;
  font-size: 10px;

  &:before {
    position: absolute;
    left: -12px;
    display: block;
    width: 8px;
    height: 1px;
    background: $colorError;
    content: "";
  }
}

.po-pdf-attachment {
  font-size: 14px;
  cursor: pointer;
}

.open-external-icon {
  margin-left: 4px;
  min-width: 11px;
  width: 11px;
  fill: $colorSuccess;
}

.attachments {
  align-items: baseline;
  margin-bottom: 16px;
  color: $colorSecondary;
  font-weight: $medium;
  font-size: 14px;
}

.delete-file-icon {
  margin-right: 4px;
  width: 11px;
  height: 11px;
  fill: $colorError;
  cursor: pointer;
}

.attachment-list {
  flex: 1;

  &-title {
    margin-bottom: 8px;
    color: $colorSecondary;
    font-weight: 500;
    font-size: 14px;
  }
}

.upload-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0 0 24px;
}

.attachment-item {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.filesize {
  margin-left: 8px;
  color: $colorSecondary;
  font-weight: 600;
  font-size: 12px;
}

.delete-file-icon {
  margin-right: 4px;
  width: 11px;
  height: 11px;
  fill: $colorError;
  cursor: pointer;
}
