@import "../../theme/variables";

.item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  padding: 8px;
  height: 34px;
  background: #f3f4f7;

  &:hover {
    outline: 1px solid rgba(0, 0, 0, 0.1);
  }

  &--disabled {
    opacity: 0.3;
    cursor: default;

    &:hover {
      outline: none;
      opacity: 1;
    }
  }

  &--dragging {
    // FIXME
    cursor: default;
  }

  &__status {
    flex-shrink: 0;
  }

  &__section {
    display: flex;
    flex: 1;
    flex-shrink: 1;
    min-width: 0;
    white-space: nowrap;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    font-size: 11px;
  }

  &__amount {
    padding: 0 5px;
    font-size: 11px;
  }

  &__code {
    margin-right: 5px;
    margin-left: 8px;
    min-width: 40px;
    text-align: right;
    font-size: 11px;
  }
}

.avatar {
  margin-left: 5px;
  min-width: 22px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 10px;

  .initials {
    padding-top: 2px;
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;
  margin-left: 8px;
  min-width: 33px;
  color: #787778;
  font-size: 12px;
}

.attachment-icon,
.comments-icon {
  margin-right: 5px;
  width: 15px;
  height: 15px;
}
