@import "../../theme/variables";

.datagrid-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
  border-spacing: 3px;
  empty-cells: show;
  font-size: 15px;
}

.table {
  table-layout: fixed;
}

.thead {
  display: table-header-group;
}

.tbody {
  display: table-row-group;
}

.th,
.td {
  padding: 8px 16px;
  text-align: left;

  &:first-child {
    font-weight: 600;
  }
}

.th {
  color: $colorSecondary;
  text-align: left;
  white-space: nowrap;
  font-weight: bold;
}

.row {
  &:hover {
    background: $colorHover;
  }
}


.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 8px;
  border-radius: $radiusSmall;
  background: $colorShade;
}
