@import "../../theme/variables";

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  padding: 24px;
  width: 100%;
  border-radius: 8px;
  background-color: $colorItem5;

  // success ribbon
  &[data-state~="SUCCESS"]::after {
    position: absolute;
    bottom: 1px;
    left: 50%;
    width: calc(100% - 2px);
    border-bottom: 8px solid $colorSuccess;
    border-radius: 0 0 $radiusLarge $radiusLarge;
    content: "";
    transform: translate(-50%);
  }

  // pending ribbon
  &[data-state~="PENDING"]::after {
    position: absolute;
    bottom: 1px;
    left: 50%;
    width: calc(100% - 2px);
    border-bottom: 8px solid $colorLabel2;
    border-radius: 0 0 $radiusLarge $radiusLarge;
    content: "";
    transform: translate(-50%);
  }
}
