@import "../../theme/variables";
@import "../../theme/mixins";

.comment {
  margin: 4px 0 4px 0;
}

.status-icon {
  width: 16px;
  height: 16px;

  fill: $colorSecondary;

  &--success {
    fill: $colorSuccess;
  }

  &--error {
    fill: $colorError;
  }
}

.button-wrap {
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
}

.thumbs-up-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;

  fill: #ffffff;
}

.thumbs-down-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  transform: rotate(180deg) scaleX(-1);

  fill: #ffffff;
}

.wrapper {
  align-items: center;
  background-color: $colorItem5;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  padding: 0.5rem;

  &--form {
    background-color: white;
  }

  &--approved {
    .position {
      &-badge {
        background-color: $labelSuccess;
      }
    }

    .user-avatar {
      &-badge {
        background-color: $labelSuccess;
      }
    }
  }

  &--cancelled {
    .position {
      &-badge {
        background-color: $labelWarning;
      }
    }

    .user-avatar {
      &-badge {
        background-color: $labelWarning;
      }
    }
  }

  &--declined {
    .position {
      &-badge {
        background-color: $labelAlert;
      }
    }

    .user-avatar {
      &-badge {
        background-color: $labelAlert;
      }
    }
  }

  &--draft {
    .position {
      &-badge {
        background-color: $labelDefault;
      }
    }

    .user-avatar {
      &-badge {
        background-color: $labelDefault;
      }
    }
  }

  &--inprogress {
    .position {
      &-badge {
        background-color: $labelInfo;
      }
    }

    .user-avatar {
      &-badge {
        background-color: $labelInfo;
      }
    }
  }

  &--form {
    .position {
      &-badge {
        background-color: $labelInfo;
      }
    }
  }

  &--header {
    .position {
      &-badge {
        background-color: #d8dae0;
      }
    }

    .status-icon {
      fill: #090509;
    }

    .content {
      width: calc(100% - 40px);
    }
  }

  @include for-size(tablet-portrait-up) {
    padding: 1rem;
  }
}

.position {
  &-badge {
    align-items: center;
    background-color: $subtitleColor;
    border-radius: 8px;
    color: white;
    display: inline-flex;
    font-size: 80%;
    font-weight: bold;
    justify-content: center;
    line-height: 1;
    padding: 3px 8px;
  }

  @include for-size(tablet-portrait-up) {
    width: 40px;

    &-badge {
      padding: 4px 12px;
    }
  }
}

.comment {
  width: 100%;
  margin-top: 1rem;
}

.content {
  padding: 0 0.5rem;
  width: calc(100% - 105px);

  &-comment {
    font-size: 14px;
  }

  &-note {
    font-size: 0.75rem;
    font-style: italic;
  }

  &-status {
    color: $subtitleColor;
    line-height: 1.4;
  }

  @include for-size(tablet-portrait-up) {
    padding: 0 1rem;
    max-width: 600px;
    width: calc(100% - 230px);

    &-note {
      font-size: 0.8rem;
    }
  }
}

.user {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  justify-content: center;
  text-align: center;
  max-width: 80px;

  &-avatar {
    position: relative;

    &-badge {
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 1;
      background: $labelDefault;
      border-radius: 50%;
      display: flex;
      padding: 2px;

      svg {
        fill: white;
        height: 10px;
        width: 10px;
      }
    }
  }

  @include for-size(tablet-portrait-up) {
    flex-wrap: nowrap;
    max-width: none;
    justify-content: initial;
    padding: 1rem;
    text-align: left;
    width: 180px;

    &-avatar {
      margin-right: 8px;
    }
  }
}
