@import "../../theme/variables";

.error-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  min-height: 50vh;
}

.title {
  text-align: center;
  font-weight: normal;
  font-size: 24px;
}

.message {
  padding: 16px 32px;
  color: $colorError;
  text-align: center;
  font-size: 20px;
}
