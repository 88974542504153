@import "../../theme/variables";
@import "../../theme/mixins";

.notification {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 128, 0, 0.95);
  color: #ffffff;
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  @include for-size(tablet-portrait-up) {
    height: $headerHeight;
  }

  &--warning {
    background-color: rgba(254, 185, 0, 0.95);
  }

  &.closed {
    transform: translateY(-100vh);

    @include for-size(tablet-portrait-up) {
      transform: translateY(-$headerHeight);
    }
  }

  .message {
    flex: 1;
    padding: 0 80px;
    text-align: center;
    font-size: 18px;
    line-height: 1.5em;

    a {
      color: #ffffff;
    }
  }

  .close-wrap {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: $headerHeight;
    background-color: rgba(0, 0, 0, 0.1); // TODO: use hex instead of rgb
    cursor: pointer;

    @include for-size(phone-only) {
      bottom: 0;
    }
    @include for-size(tablet-portrait-up) {
      top: 0;
      width: $headerHeight;
      height: 100%;
    }
  }

  .close-icon {
    width: 48px;
    height: 48px;

    fill: #ffffff;
  }
}
