@import "../../theme/variables";
@import "../../theme/mixins";

.menu {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  border-bottom: 1px solid $colorMedium;
  background: $colorShade;

  .section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .link {
    display: inline-block;
    padding: 25px 5px;
    font-size: 80%;
    color: $colorSecondary;
    text-decoration: none;
    font-weight: bold;

    &:focus {
      outline: none;
      text-decoration: underline;
    }

    &:hover {
      color: $colorSuccess;
    }

    &--active {
      position: relative;
      color: $colorSuccess;
    }

    @include for-size(tablet-portrait-up) {
      font-size: 15px;
      padding: 25px 15px;
    }
  }

  .link--disabled {
    &:hover {
      color: $colorSecondary;
    }
  }

  .badge {
    background-color: $labelAlert;
    border-radius: 50%;
    color: $colorLight;
    display: inline-block;
    font-size: 65%;
    font-weight: 400;
    left: -4px;
    line-height: 1.25;
    padding: 1.306px 3.3px;
    position: relative;
    text-align: center;
    top: -7px;

    @include for-size(tablet-portrait-up) {
      font-size: 75%;
      padding: 2.375px 6px;
    }
  }

  @include for-size(phone-only) {
    display: block;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 1;

    .link {
      padding: 10px 5px;
    }
  }
}
