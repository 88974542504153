@import "../../theme/variables";

.card {
  $sectionBorder: 1px solid #ededef;

  margin-right: 4px;
  margin-bottom: 1px;
  width: calc(100% - 4px);
  border-radius: $radiusSmall;
  background: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);

  user-select: none;

  &:hover {
    background: #fafafb;
  }

  // footer change assignee dropdown styles
  &:last-of-type {
    .dropdown-content {
      top: auto;
      right: 100%;
      bottom: 0;
      max-height: 128px;
    }
  }

  & + .card {
    margin-top: 9px;
  }

  &--purchase-request {
    &:hover {
      background: #ffffff;
    }
  }

  .section {
    position: relative;
    padding: 8px;
  }

  .click-target {
    cursor: pointer;
  }

  .unfocused {
    opacity: 0.3;
  }

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: $sectionBorder;

    .code-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 8px;
    }

    .card-code {
      margin-right: 5px;
      color: #000000;
      font-weight: $medium;
      font-size: 12px;
    }
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .title {
    overflow: hidden;
    margin: 0;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: $medium;
    font-size: 14px;
  }

  .description-wrapper {
    margin-bottom: 10px;
  }

  .date {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 5px;
  }

  .secondary-text {
    color: #787778;
    font-size: 12px;
  }

  .requestor {
    margin-right: 5px;
  }

  .description {
    &--has-warning {
      color: #f55c21;
      font-weight: $medium;
    }
  }

  .label-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .toggle-more-wrap {
    &--bottom {
      padding: 10px 0;
      text-align: center;
    }

    &--top {
      position: absolute;
      top: 36px;
      right: 8px;
    }
  }

  .toggle-more-button {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    color: #6d6d6d;
    text-decoration: underline;
    font-size: 13px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px;
    border-top: $sectionBorder;

    &__section {
      display: flex;
    }

    &__title {
      margin-right: 10px;
      margin-bottom: 5px;
      color: #6f6f6f;
      letter-spacing: 0.5px;
      font-size: 11px;
    }

    &__data {
      overflow: hidden;
      max-width: 80px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;

      &:empty {
        display: none;
      }
    }

    .data-block + .data-block {
      margin-left: 5px;
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    margin-left: 8px;
    color: #787778;
    font-size: 12px;
  }

  .attachment-icon,
  .comments-icon {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }

  &-items {
    background-color: inherit;
    &--over {
      background-color: rgb(167, 255, 167);
    }
  }
}
