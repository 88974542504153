@import "../../theme/variables";

.menu-filter {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  + .menu-filter {
    margin-left: 15px;
  }
}

.label {
  margin-bottom: 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

.filter__selector {
  position: relative;
  padding: 0 30px 0 8px;
  height: 32px;
  border: 1px solid $colorMedium;
  border-radius: $radiusSmall;
  text-align: left;
  font-size: 13px;

  &:focus {
    outline: none;
    box-shadow: $globalFocus;
  }

  &:after {
    position: absolute;
    top: calc(50% - 2px);
    right: 8px;
    width: 7px;
    height: 7px;
    border-right: 2px solid #222222;
    border-bottom: 2px solid #222222;
    background: transparent;
    content: "";
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.filter-button {
  &--open {
    background: $colorLight;
  }

  &--filter-active {
    color: $colorSuccess;

    svg {
      fill: $colorSuccess;
    }
  }
}
