@import "../../theme/variables";
@import "../../theme/mixins";

.view {
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;

  @include for-size(phone-only) {
    justify-content: center;
    margin: auto;
  }
}

.section {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  padding: 40px;
  min-width: 504px;
  max-width: 750px;
  width: calc(100% / 3);

  @include for-size(phone-only) {
    min-width: 320px;
    width: 100%;
  }
}

.logo__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;

  .logo {
    margin-right: 16px;
  }
}

// ILLUSTRATION
.illustration__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background: #f0f7f1;

  @include for-size(phone-only) {
    display: none;
  }

  .illustration {
    display: none;

    @include for-size(tablet-landscape-up) {
      display: inherit;
      padding: 40px;
      max-width: 100%;
      width: 568px;
    }
  }
}
