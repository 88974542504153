@import "../../theme/variables";

.section {
  &-subtitle {
    color: $subtitleColor;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
