@import "../../theme/variables";

.checkbox {
  $checkboxButtonSize: 22px;

  display: flex;
  cursor: pointer;

  &:hover {
    &__input ~ &__checkmark {
      background-color: #ffffff;
    }
  }

  &__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked {
      ~ .checkbox__checkmark {
        position: relative;
        border: none;
        background: $colorSuccess;

        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          color: #ffffff;
          content: "\2713";
          font-size: 18px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &__checkmark {
    position: inline-block;
    width: $checkboxButtonSize;
    height: $checkboxButtonSize;
    border: 1px solid $colorMedium;
    border-radius: 4px;
    background: #ffffff;

    &:after {
      display: none;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    padding-left: 8px;
    line-height: 17px; // odd line-height is needed for proper vertical centering
  }
}
