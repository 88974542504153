@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Rubik", sans-serif;
}

.heading {
  margin-bottom: 4px;
  margin-left: 34px;
  color: $colorTextContrast;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}

.item-icon {
  flex: 0 1 18px;
  margin-right: 16px;
  min-width: 18px;
  min-height: 18px;
  fill: $labelDefault;
  color: $labelDefault;
}

.question-input {
  composes: input from "../Field/Field.module.scss";

  margin-right: $fieldMargin;
}

.add-button {
  border: 1px solid $colorMedium;
  margin-left: 34px;
  width: 120px;
}

.add-button-icon {
  height: 10px !important;
}

.remove-button {
  border: 1px solid $colorMedium;

  svg {
    fill: orange;
  }

  &:hover {
    box-shadow: none;
  }

  &:focus {
    border-color: orange;
  }
}

.duplicate-button {
  border: 1px solid $colorMedium;

  svg {
    fill: $labelSuccess;
  }

  &:hover {
    box-shadow: none;
  }

  &:focus {
    border-color: labelSuccess;
  }
}

