@import "../../theme/variables";

.company-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  max-height: 56px;
  width: auto;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
