@import "../../theme/variables";
@import "../../theme/mixins";

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 4px 8px;
  border-radius: 50%;
  background-color: $subtitleColor;
  color: white;
  font-weight: bold;
  font-size: 80%;
  line-height: 1.1;
}

.blank {
  @include for-size(phone-only) {
    display: none;
  }
}

.title {
  display: flex;
  align-items: center;

  &-parent {
    margin: 0 0 0 16px;
    font-size: 12px;
  }

  &-status {
    margin-left: 12px;
  }
}

.items {
  position: relative;
  margin: 0.5rem 0 1rem;
  width: 100%;
  border-bottom: 4px solid $subtitleColor;

  table {
    width: 100%;
    border-spacing: 0 1em;

    tr td:first-child {
      text-align: right;

      @include for-size(desktop-up) {
        width: 50px;
      }
    }
  }

  &::after {
    position: absolute;
    bottom: 3px;
    width: 100%;
    height: 2px;
    background-color: $subtitleColor;
    content: "";
  }
}

.item {
  margin: 1rem 0;

  h3 {
    font-weight: 400;
  }
}

.item-details {
  width: 100%;
  font-size: 0.85rem;

  @include for-size(desktop-up) {
    font-size: 1rem;

    &-title {
      margin-top: 1.5rem;
    }
  }
  @include for-size(phone-only) {
    &-subtitle {
      font-size: 0.85rem;
    }
  }

  &-subtitle {
    margin-bottom: 0;
    color: $subtitleColor;
    font-weight: 400;
  }

  &-title {
    text-transform: uppercase;
  }

  &-value,
  &-link {
    font-weight: bold;
  }
}

.item-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
  font-size: 80%;

  @include for-size(phone-only) {
    &-link {
      width: 120px;
    }
  }

  &-title {
    margin-top: 0;
  }

  &-link {
    display: block;
    max-width: 400px;
    overflow: hidden;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.group {
  display: flex;
  margin-top: 5px;

  @include for-size(phone-only) {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    &--values {
      margin-top: 10px;
    }

    &--values-product {
      > div:nth-child(1) {
        width: 30%;
      }

      > div:nth-child(2) {
        width: 10%;
      }

      > div:nth-child(3) {
        width: 50%;
      }
    }
  }
  @include for-size(desktop-up) {
    justify-content: flex-start;
    margin-top: 0;

    > div {
      margin-top: 5px;
      width: 16.65%;
    }

    &--info {
      flex-grow: 1;
      flex-wrap: wrap;
      width: calc(100% - 320px);
    }

    &--values {
      width: 320px;

      > div {
        width: 50%;
      }
    }

    &--secondary {
      > div {
        width: 33.33%;
      }
    }
  }

  > div {
    margin-top: 3px;
    width: 50%;
  }

  &--values {
    > div {
      width: 33.3%;
    }
  }
}

.total {
  text-align: right;
  font-weight: 400;
  font-size: 1rem;

  @include for-size(desktop-up) {
    font-size: 1.2rem;
  }
}
