@import "../../theme/variables";
@import "../../theme/mixins";

.input {
  @include for-size(tablet-landscape-max) {
    width: 100%;
  }
}

.esourcing-field {
  align-items: center;
  display: flex;

  label {
    display: block;
    width: 100%;
  }

  button {
    margin-left: 10px;

    svg {
      fill: $colorError;
    }
  }
}

.remove-button {
  border: none;
  background: transparent;

  svg {
    fill: orange;
  }

  &:hover {
    box-shadow: none;
  }

  &:focus {
    border-color: orange;
  }
}
