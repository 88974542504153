@import "../../theme/variables";

.mentions {
  padding: 2px 0;
}

.mentions--singleLine .mentions__highlighter {
  padding: 2px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  width: 100%;
  border: 1px transparent;
  margin: 0;
  padding: 0;
  outline: none;
  color: $colorInput;
  line-height: 30px;
}

.mentions--multiLine .mentions__control {
  font-size: 16px;
}

.mentions__suggestions__list {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: white;
  font-size: 10px;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  padding: 2px 0 2px 2px;
  border-radius: 5px;
  background-color: #cee4e5;
}

.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dfe0e6;
  border-radius: 4px;
  padding: 7px;
  margin-bottom: 12px;
  background-color: white;
}

.chip_wrap {
  margin-bottom: 6px;
}

.remove-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 4px;
  padding-top: 2px;
  width: 10px;
  height: 10px;
  background: transparent;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 8px;
    height: 1px;
    background: $labelRemove;
    content: "";
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:hover,
  &:focus {
    transform: scale(1.4);
  }

  &:active {
    transform: scale(1);
  }
}

.divider {
  margin-left: 8px;
  color: white;
}

.error {
  margin-bottom: 12px;
}
