@import "../../theme/variables";

.wrap {
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
}

.number {
  margin-right: 10px;
  margin-bottom: 16px;
  min-width: 40px;
  width: 40px;
  text-align: right;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
}

.add {
  margin-left: 50px;
}

.clear {
  margin-bottom: 16px;
  margin-left: 5px;
  background: transparent;

  svg {
    fill: orange;
  }

  &:hover {
    box-shadow: none;
  }

  &:focus {
    border-color: orange;
  }
}
