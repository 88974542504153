@import "../../theme/variables";
@import "../../theme/mixins";

.page {
  &-title {
    font-size: 1.3em;
    margin-bottom: 0.5rem;

    @include for-size(desktop-up) {
      font-size: 1.5em;
      margin-bottom: 1rem;
    }
  }
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  & > button:last-child {
    margin-left: 1.5rem;
    padding-left: 12px;

    svg {
      fill: white;
      height: 14px;
      margin-right: 6px;
    }
  }

  @include for-size(tablet-landscape-max) {
    margin-bottom: 2.5rem;
  }
}
