@import "../../theme/variables";

.list-enter {
  opacity: 0.01;
}
.list-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.list-exit {
  max-height: 500px;
  height: 100%;
}
.list-exit-active {
  max-height: 0;
  height: 100%;
  opacity: 0;
  transition: max-height 500ms, opacity 500ms;
}

.position-fixed {
  position: fixed;
  top: 20px;
}
