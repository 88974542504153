@import "../../theme/variables";
@import "../../theme/mixins";

.form-footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  & > button:last-child {
    margin-left: 1.5rem;
    padding-left: 12px;

    svg {
      fill: white;
      height: 14px;
      margin-right: 6px;
    }
  }

  @include for-size(tablet-landscape-max) {
    margin-bottom: 2.5rem;
  }
}
