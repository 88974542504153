@import "../../theme/variables";

.status {
  display: inline-flex;
  align-items: center;
  padding: 4px 4px 2px;
  border-radius: $radiusSmall;
  color: rgba(0, 0, 0, 0.5);
  text-align: right;
  font-weight: $medium;
  font-size: 10px;

  &--secondary {
    margin-right: 8px;
    padding: 0;
    width: 16px;
    height: 8px;
  }

  &--default {
    background: $statusDefault;
  }

  &--alert {
    background: $statusAlertBackground;
    color: $statusAlertColor;
  }

  &--warning {
    background: $statusWarning;
  }

  &--info {
    background: $statusInfo;
  }

  &--success {
    background: $statusSuccess;
  }

  &--pink {
    background: $statusPink;
  }
}
