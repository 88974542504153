@import "../../theme/variables";

.field {
  position: relative;
  margin-bottom: $fieldMargin;

  &--short {
    width: $shortField;
  }

  &--center {
    margin: auto auto;
  }

  &--checkbox {
    font-size: 0; // removes white space from the bottom

    .checkbox {
      font-size: 16px;
    }
  }

  &--uneditable {
    flex: 1 100%;
  }

  .error {
    margin-top: 8px;
    color: $colorError;
    font-size: 14px;
  }
}

.information {
  display: flex;
  width: 100%;
}

.left-column {
  display: inline-block;
  width: 300px;
  font-weight: bold;
}
.right-column {
  display: inline-block;
  width: calc(100% - 300px);
}

// TEXTAREA
.input,
.textarea {
  padding: 7px;
  width: 100%;
  border: 1px solid $colorMedium;
  border-radius: $radiusSmall;
  color: $colorInput;
  font-size: 15px;
  transition: all 200ms ease;

  &--center {
    text-align: center;
  }

  &:active,
  &:focus {
    border-color: $colorSuccess;
    transition: all 200ms ease;
  }

  // removes default behavior
  &:focus {
    outline: none;
  }
}

// CHECKBOX
.checkbox {
  $checkBoxSize: 20px;

  display: inline-flex;
  cursor: pointer;

  &:hover {
    &__input ~ &__checkmark {
      background-color: #ffffff;
    }
  }

  &--disabled {
    cursor: auto;

    &:focus {
      + .checkmark {
        border: 1px solid $colorMedium;
      }
    }
  }

  .input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;

    &:focus {
      + .checkmark {
        border: 1px solid $colorSuccess;
      }
    }

    &:checked {
      ~ .checkmark {
        position: relative;
        border: none;
        background: $colorSuccess;

        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 6px;
          height: 11px;
          border-right: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
          content: "";
          transform: translate(-50%, calc(-50% - 1px)) rotate(45deg);
        }
      }
    }
  }

  .placeholder {
    display: flex;
    align-items: center;
    padding-left: 8px;
    line-height: 17px; // odd line-height is needed for proper vertical centering
  }

  .checkmark {
    display: inline-block;
    width: $checkBoxSize;
    height: $checkBoxSize;
    border: 1px solid $colorMedium;
    border-radius: 2px;
    background: #ffffff;

    &:after {
      display: none;
    }
  }
}

// FIELD ADDON

.addon-wrap {
  display: flex;

  .addon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    min-width: $addonHeight;
    height: $addonHeight;
    border: 1px solid $colorMedium;
    border-right: none;
    border-radius: $radiusSmall 0 0 $radiusSmall;
    background: $colorShade;
    fill: $colorSecondary;
    color: $colorSecondary;
    text-align: center;
    font-weight: $medium;
    font-size: 13px;

    > * {
      height: 30px;
      border: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  select {
    margin: 0 -4px; // <Select> has it's own padding, so we want to reposition it accordingly to align with addon-wrap
  }

  .input {
    flex-grow: 1;
    width: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

table {
  .field {
    margin-bottom: 0;
  }
  .table-create-new-field {
    margin-bottom: 16px;
  }
}

// this is used to push field inside modal footer to the right
.push {
  margin-right: auto;
}

.zero-margin {
  margin-bottom: 0;
}
