@import "../../theme/variables";

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $fieldMargin;
  width: 200px;
  height: 32px;
  outline: none;
  border: 1px dashed $colorMedium;
  border-radius: $radiusSmall;
  background: rgba(255, 255, 255, 0.5);
  color: $colorSuccess;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  font-family: Rubik, sans-serif;
  cursor: pointer;

  .attachment-icon {
    width: 15px;
    height: 15px;

    &--secondary {
      width: 11px;
      height: 11px;
    }
  }

  &--active {
    border-color: $colorSuccess;
    color: $colorSuccess;
  }

  &--rejected {
    border-color: $colorInfo;
  }

  &--loading {
    // FIXME
    border-color: $colorMedium;
  }

  &--with-error {
    border-color: $colorError;
  }

  &:focus {
    border-color: darken($colorMedium, 15%);
    color: darken($colorSecondary, 15%);
    transition: all 200ms ease;
  }

  &:hover {
    border-color: darken($colorMedium, 15%);
    color: darken($colorSecondary, 15%);
    transition: all 200ms ease;
  }

  &--collaboration-feed {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
    margin-left: 8px;
    width: initial;
    height: initial;
    border: none;
    background: none;
    color: #00bf3a;
    font-size: 11px;
    font-family: "Rubik", sans-serif;

    &:hover {
      color: $colorSuccess;
      text-decoration: underline;
    }
  }

  &--secondary {
    margin-bottom: 0;
    width: auto;
    border: none;
    background: none;
    color: $colorSuccess;
  }
}

.error {
  color: $colorError;
}

.warning {
  margin-left: 8px;
  color: $colorInfo;

  &--collaboration-feed {
    font-size: 11px;
  }
}
