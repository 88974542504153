@import "../../theme/variables";

.wrap {
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 0;
  width: max-content;
  height: 32px;
  border-radius: 8px;
  background-color: white;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  margin: 0 8px;
  color: #787778;
  font-size: 12px;
}

.icon {
  width: 15px;
  height: 15px;
}

.link {
  flex: 0 1 1000px;
  margin-right: 8px;
  color: $colorSuccess;
  text-decoration: none;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;

  &:hover {
    color: $colorSuccess;

    .name {
      color: $colorSuccess;
    }
  }
}

.avatar {
  flex: 1;
}
