@import "../../theme/variables";

.wrap {
  margin: 0 0 44px 24px;
  color: $colorInput;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
}

// for responsiveness and better styling reasons fake the tables
.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
}

.head {
  display: table-row;
  border: 1px solid $colorShade;
  font-weight: 500;
}

.row {
  display: table-row;
  border: 1px solid $colorShade;
}

.cell {
  display: table-cell;
  padding: 4px 0;
  vertical-align: top;
}

.first-col {
  width: 200px;
}

.second-col {
  width: auto;
}

.third-col {
  width: 100px;
}

.subheading-row {
  display: table-row;
  height: 44px;
  border-top: solid $cardColorRfxBody 16px;
  border-bottom: solid $cardColorRfxBody 16px;
  background: rgba(0, 0, 0, 0.06);
  font-weight: 400;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
}

.subheading-col {
  display: table-cell;
  padding-left: 8px;
  vertical-align: middle;
}

.email {
  margin-bottom: 16px;
}

.supplier-name {
  max-width: 160px;
  word-wrap: break-word;
  font-weight: 500;
  font-size: 14px;
}

.supplier-email {
  max-width: 160px;
  color: $colorInput;
  word-wrap: break-word;
  font-weight: 300;
  font-size: 12px;
}

.no-emails {
  font-style: italic;
}
