@import "../../theme/variables";

.modal {
  background: $cardColorRfxBody !important;
}

.wrap {
  min-height: 400px;
}

.label-dropdown {
  align-self: center;
}

.addon-wrap {
  display: flex;
  gap: $fieldMargin;
  position: relative;
}

.modal-header {
  background: $cardColorRfxHeader !important;
}

.rfx-name-wrap {
  display: flex;
  align-items: center;
}

.rfx-code {
  margin-right: 8px;
  min-width: fit-content;
  font-weight: 700;
}

.name-input {
  margin-bottom: 0;
  width: 350px;
}

.name-edit-icon {
  margin-left: 4px;
  height: 16px;
  fill: $colorSecondary;
  cursor: pointer;
}

.save-name-button {
  margin-left: 8px;
}

.request-type-section {
  display: flex;
}

.progress-steps {
  display: flex;
  margin-bottom: 16px;
}

.step {
  margin-right: 4px;
}

.emailFormWrapper {
  margin: 16px 0;
}

.fieldLabel {
  min-width: 80px;
  font-weight: bold;
}

.emailAddress {
  margin: 4px 4px 4px 0;
  padding: 4px;
  border-radius: $radiusLarge;
  background-color: $statusSuccess;
}

.note {
  display: flex;
}

.emailNote {
  margin: 0 0 8px 8px;
  font-weight: lighter;
}

.emailFormHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f5f1e4;
  font-weight: bold;
  cursor: pointer;
}

.emailFormTitle {
  display: flex;
  align-items: center;
}

.oneToManyIcon {
  width: 48px;
  height: 24px;
}

.emailFormBody {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #f1eae1;
}

.emailFrom,
.emailTo,
.ccField,
.subjectField,
.messageField {
  display: flex;
  align-items: center;
  margin: 8px;
}

.emailTo {
  flex-wrap: wrap;
}

.ccInput,
.subjectInput {
  margin: 0;
}

.attachments {
  align-items: baseline;
  margin-bottom: 24px;
  margin-left: 88px;
  color: $colorSecondary;
  font-weight: $medium;
  font-size: 14px;
}

.delete-file-icon {
  margin-right: 8px;
  width: 11px;
  height: 11px;
  fill: $colorError;
  cursor: pointer;
}

.request-status {
  margin-right: auto;
}

.button-wrap {
  display: flex;
  margin-left: 88px;
  width: calc(50% - 12px);
}

.button .email-sent {
  margin-top: 24px;
}

.sourcing-event-info {
  margin: 0 0 64px 32px;
}

.offers {
  margin-bottom: 64px;
}

.winner-select-container {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
}

.winner-select-outer {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  width: 100%;
}

.winner-select-inner {
  padding: 24px 32px;
  width: 40vw;
  border-radius: 4px;
  background: white;
}

.winner-select-title {
  margin-bottom: 24px;
}

.winner-select {
  min-width: 200px;
}

.winner-select-submit {
  margin-left: auto;
}

.add-and-email-suppliers {
  margin-bottom: 80px;
}

.archive-icon {
  fill: $labelRemove;
}
