@import "../../theme/variables";
@import "../../theme/mixins";

.overlay {
  @include scrollbar;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(110, 110, 111, 0.8);
}
