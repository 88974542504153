@import "../../theme/variables";
@import "../../theme/mixins";

.wrap {
  margin: 12px 16px 16px 24px;
}

.from {
  margin: 4px 0 16px 0;
}

.body {
  margin: 24px 0;
  white-space: pre-line;
}

.to {
  margin: 4px 0 16px 0;
}

.file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
  padding-right: 16px;
}

.attachment {
  display: flex;
  align-items: baseline;
  margin: 8px 0 0 12px;
  color: $colorSecondary;
  font-weight: $medium;
  font-size: 12px;
}

.filename {
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.open-external-icon {
  margin-left: 4px;
  min-width: 11px;
  width: 11px;

  fill: $colorSuccess;
}

.strong {
  margin: 12px 0;
  font-weight: 600;
  font-size: 16px;
}
