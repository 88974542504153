@import "../../theme/variables";

.wrap {
  display: block;
}

.item-information {
  display: grid;
  grid-template-columns: 70px auto 1fr;
  grid-template-areas: "select icon info";
  font-family: Rubik, sans-serif;
}

.type-icon {
  grid-area: icon;
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.info-area {
  grid-area: info;
}

.info-area-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.item-name {
  margin-bottom: 8px;
  min-width: 100px;
  font-weight: bold;
  font-size: 14px;
}

.quantity-text {
  display: block;
  width: 250px;
}

.important-text {
  color: darkred;
}

.item-details {
  font-size: 12px;
  opacity: 0.6;
}

// override default padding to make left edges align
.add-button-cell {
  padding: 10px 0 !important;
}

.empty-wrap {
  margin-bottom: 16px;
}

.empty-message {
  margin-bottom: 16px;
  font-style: italic;
}

.button-icon {
  fill: $colorSuccess;
}

.field-container {
  display: flex;
  justify-content: flex-end;
}

.field-label {
  margin-right: 12px;
}

.vat-input {
  margin: 0;

  & input {
    width: 60px !important;
  }
}

.additional-row {
  display: flex;
  align-items: center;
  margin-top: 8px;
  word-break: break-all;
}

.row-icon {
  margin-right: 13px;
  min-width: 16px;
  height: 16px;
}

.date-margin {
  margin-bottom: 14px;
}

.add-items {
  display: flex;
  justify-content: space-between;

  .upload-excel {
    margin-bottom: 16px;
    color: $colorSuccess;
    font-weight: 600;
    cursor: pointer;
  }
}

.request-info {
  display: flex;
  justify-content: flex-end;

  &-text {
    overflow: hidden;
    margin-top: 4px;
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
  }

  &-link {
    color: $colorSuccess;
    text-decoration: underline;
    cursor: pointer;
  }
}

.in-progress {
  opacity: 0.3;

  .progress-info {
    font-size: 12px;

    .avatar {
      margin-left: 5px;
      min-width: 22px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      font-size: 10px;

      .initials {
        padding-top: 2px;
      }
    }
  }
}

.no-details {
  display: block;
  margin-top: 4px;
}

.cta-buttons {
  display: flex;
  align-items: center;

  button {
    margin: 0 8px;
  }
}

.items-table-header {
  height: 60px;
}

.dropdown-selector {
  .move-to-button {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .triangle-arrow-icon {
    width: 13px;
    fill: white;
  }
}

.dropdown-content {
  overflow-y: auto;
  margin-left: 16px;
  padding: 4px 6px 6px 6px;
  max-height: 300px;
  width: 300px;
  border: 1px solid $colorSuccess;
  font-weight: 400;

  &--autoWidth {
    width: auto;
  }

  .create-new {
    margin-bottom: 6px;
    padding: 6px;
    border-bottom: 1px solid $colorSuccess;
    font-weight: 600;
  }

  .dropdown-item {    
    overflow: hidden;
    padding: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;

    .sort-type {
      font-weight: bold;
    }

    &--withSorting {
      display: flex;  
      justify-content: space-between;
      gap: $fieldMargin;
    }

    &--withSelect {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
