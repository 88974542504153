@import "./variables";

// media query mixins
// https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
@mixin for-size($range) {
  @if $range == phone-only {
    @media only screen and (max-width: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media only screen and (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media only screen and (min-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-up {
    @media only screen and (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media only screen and (min-width: $desktop-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-portrait-max {
    @media only screen and (max-width: #{$tablet-portrait-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-landscape-max {
    @media only screen and (max-width: #{$tablet-landscape-upper-boundary - 1}) {
      @content;
    }
  }
}

// custom scrollbar
@mixin scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
  }
}

/*
Mixin for multiline text ellipsis
http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
*/
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  position: relative;
  overflow: hidden;
  margin-right: -1em;
  padding-right: 1em;
  max-height: $lineHeight * $lineCount;
  text-align: left;
  line-height: $lineHeight;
  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    content: "...";
  }
  &:after {
    position: absolute;
    right: 0;
    margin-top: 0.2em;
    width: 1em;
    height: 1em;
    background: $bgColor;
    content: "";
  }
}

// tab button
@mixin tabButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: $colorItem1;
  fill: #000000;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #000000;
  text-align: center;
  font-weight: bold;
  opacity: 0.4;

  svg {
    $smallSize: 30px;

    flex-basis: $smallSize;
    margin-right: 8px;
    min-width: $smallSize;
    width: $smallSize;
    height: $smallSize;

    @include for-size(tablet-portrait-up) {
      $largeSize: 40px;

      flex-basis: $largeSize;
      margin-right: 16px;
      min-width: $largeSize;
      width: $largeSize;
      height: $largeSize;
    }
  }
}

// active tab button
@mixin tabButtonActive {
  position: relative;
  background: $colorAliceBlueDark;
  opacity: 1;
}

// arrow for active tab button
@mixin tabButtonArrow {
  position: absolute;
  top: calc(100% - 2px);
  left: 50%;
  margin: 0;
  height: auto;
  fill: $colorAliceBlueDark;
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.5));
  transform: translateX(-50%);
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

// container query
// https://css-tricks.com/holy-albatross-with-widths/
// http://www.miragecraft.com/articles/unholy_albatross.html
@mixin containerQuery($breakpoint, $minWidth, $gutter) {
  display: flex;
  flex-wrap: wrap;
  margin: calc(#{$gutter} / 2 * -1);

  & > * {
    margin: calc(#{$gutter} / 2);
    min-width: calc(clamp(#{$minWidth}, (#{$breakpoint} - 100%) * 999, 100% - #{$gutter}));
  }
}
