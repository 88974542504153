@import "../../theme/variables";
@import "../../theme/mixins";

.wrap {
  display: flex;
}

.email-lookup-form {
  margin: 48px 0 0 48px;

  @include for-size(tablet-portrait-up) {
    margin: 48px 0 0 216px;
  }

  .email-lookup-actions {
    margin-top: 16px;
  }
}

.email-lookup-label {
  composes: label from "../FieldLabel/FieldLabel.module.scss";

  text-transform: none;
  font-size: 14px;

  &--required {
    composes: label--required from "../FieldLabel/FieldLabel.module.scss";
  }
}

.email-lookup-field {
  composes: input from "../Field/Field.module.scss";

  width: 240px;
}
