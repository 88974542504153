@import "../../theme/mixins";

.group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  &-item {
    width: 50%;
    padding-right: 1rem;
  }

  @include for-size(desktop-up) {
    width: 50%;
  }
}
