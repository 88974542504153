@import "../../theme/variables";

.title {
  display: block;
  margin: 15px 0 5px;
}

.code {
  font-size: 14px;
  color: $colorSuccess;
  background: white;
  border: 1px solid $colorMedium;
  padding: 2px 4px;
  border-radius: 4px;
}
