@import "../../theme/variables";
@import "../../theme/mixins";

.avatar {
  margin-left: 8px;
}

.actions {
  text-align: center;

  @include for-size(tablet-portrait-up) {
    min-width: 80px;
  }
}

.container {
  background-color: $colorItem5;
  border-radius: 8px;
  color: $colorInput;
  position: relative;
  margin-bottom: 8px;
  padding: 12px;
  padding-bottom: 22px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.status {
    &--approved {
      &::after {
        background-color: $labelSuccess;
      }

      .details-view-link {
        fill: $labelSuccess;
      }
    }

    &--canceled {
      &::after {
        background-color: $labelWarning;
      }

      .details-view-link {
        fill: $labelWarning;
      }
    }

    &--declined {
      &::after {
        background-color: $labelAlert;
      }

      .details-view-link {
        fill: $labelAlert;
      }
    }

    &--draft {
      &::after {
        background-color: $labelDefault;
      }

      .details-view-link {
        fill: $labelDefault;
      }
    }

    &--inprogress {
      &::after {
        background-color: $labelInfo;
      }

      .details-view-link {
        fill: $labelInfo;
      }
    }

    @include for-size(tablet-portrait-up) {
      padding-bottom: 32px;
    }
  }

  &--secondary {
    &.status {
      &--approved {
        @include background-opacity($labelSuccess, 0.25);
      }

      &--canceled {
        @include background-opacity($labelWarning, 0.25);
      }

      &--declined {
        @include background-opacity($labelAlert, 0.25);
      }

      &--draft {
        @include background-opacity($labelDefault, 0.25);
      }

      &--inprogress {
        @include background-opacity($labelInfo, 0.25);
      }
    }
  }

  @include for-size(tablet-portrait-up) {
    padding: 22px 22px 32px;
  }
}

.content {
  flex-grow: 1;
}

.deadline {
  margin-top: 8px;
}

.details-view-link {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);

  &--secondary {
    display: none;
  }
}

.price {
  padding: 4px;
  border-radius: $radiusSmall;
  background: #ffffff;
  font-weight: bold;
}

.row {
  display: flex;
  align-items: center;
}

.requester {
  margin: 0 8px;
}

.small-gap {
  margin-bottom: 4px;
}

.small-text {
  font-size: 12px;
}

.status {
  display: none;

  @include for-size(tablet-portrait-up) {
    display: block;
    position: relative;
    top: -15px;
  }
}

.summary {
  display: flex;
  flex-wrap: wrap;

  @include for-size(tablet-portrait-max) {
    font-size: 0.8rem;
  }
}

.summary-item {
  margin-top: 0.5rem;
  padding-right: 0.5rem;
  width: 50%;

  @include for-size(tablet-portrait-up) {
    padding-right: 2rem;
    width: 25%;
    margin-top: 1rem;
  }
}

.summary-item__value {
  margin-top: 0.2rem;
}

.text-color-secondary {
  color: $colorSecondary;
}

.title {
  @include for-size(tablet-portrait-max) {
    font-size: 1.2em;
  }
}
