@import "../../theme/variables";

.modal {
  background: $colorItem2;
}

.wrap {
  margin: -16px -16px 0 0;
}

.label {
  margin-bottom: $fieldMargin;
}

.label-dropdown {
  align-self: center;
}

.addon-wrap {
  display: flex;
  gap: $fieldMargin;
  position: relative;
}

.receiving-container {
  display: grid;
  grid-template-columns: minmax(auto, 1fr) minmax(0, auto);
  grid-template-areas: "left right";
  min-height: 600px;
}

.left-container {
  grid-area: left;
  margin: 12px 8px 0 0;
  padding-right: 12px;
}

.right-container {
  overflow-x: hidden;
  grid-area: right;
  border-left: 1px solid rgba(0, 0, 0, 0.085);
}

.confirmation-date {
  margin-top: 4px;
  margin-left: 4px;
  font-weight: $medium;
}

.avatar {
  margin-right: 16px;
}

.add-button-cell {
  padding: 10px 0;
}

.archive-icon {
  fill: $labelRemove;
}
