@import "../../theme/variables";

.comment-form {
  margin-bottom: 16px;

  .attachments {
    margin-bottom: 12px;
    color: $colorSecondary;
    font-size: 14px;
  }

  .delete-file-icon {
    margin-right: 8px;
    width: 11px;
    height: 11px;
    fill: $colorError;
    cursor: pointer;
  }

  .filename {
    overflow: hidden;
    max-width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.action-wrap {
  display: flex; // should not be necessary with spans
  flex-wrap: wrap;
  margin-bottom: 16px;

  .icon {
    min-width: 11px;
    width: 11px;
    height: 11px;
  }

  .section {
    display: flex;
    align-items: flex-start;

    > :nth-child(n + 2) {
      margin-left: 8px; // target every direct child but the first
    }
  }
}

.action {
  color: $colorSuccess;
  font-weight: bold;
  font-size: 11px;
}

.textarea-wrap {
  margin-bottom: 4px;
}

.button {
  margin-left: auto;
}
