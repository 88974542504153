.row {
  display: flex;

  &--secondary {
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  button + button {
    margin-left: 8px;
  }
}
