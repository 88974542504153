// COLORS
// TODO: attempt to unify
$colorInput: #333333; // text color
$colorTextContrast: #0c0c0c; // contrasting text color
$colorSecondary: #888888; // light text, hover, icon, modal close btn
$colorTertiary: #acacac; // lighter text
$colorDisabled: #faf9f5;
$colorReadonly: #f2f3f5;
$colorMedium: #d8d8d8; // borders
$colorShade: #e9eaee; // dark backgrounds (icon backgrounds, field addon backgrounds etc)
$colorLight: #f8f8fa; // light backgrounds (modal, sidebar etc)
$colorSuccess: #00bf3a;
$colorError: #f55c21;
$colorInfo: #feb900;
$colorItem1: #fef8e6;
$colorItem2: #e6f5fb;
$colorItem3: #e6f8eb;
$colorItem4: #60aef6; // button
$colorItem5: #f3f4f7; // notification
$colorItem6: #f4f4f7;
$colorItem7: #fff0b2;
$colorItem8: #009cdf;
$colorLabel1: #ce18ce;
$colorLabel2: #6214a7;
$colorLabel3: #3dd0ff;
$colorLightTitle: #9b9b9b;
$colorHover: #f0f8f3; // hover
$colorAltView: #b8e6fe;
$colorAliceBlueLight: #f6fbff;
$colorAliceBlueDark: #ddf4ff;
$colorGhostWhite: #f7f7f9;
$statusDefault: #d7d7d7;
$statusInfo: #99d7f2;
$statusAlertBackground: #f55c21;
$statusAlertColor: #ffffffcc;
$statusWarning: #fedc80;
$statusSuccess: #99e5b0;
$statusPink: #fab499;
$labelDefault: #979797;
$labelInfo: #009cdf;
$labelAlert: #f55c21;
$labelRemove: #ff0000;
$labelWarning: #f5a623;
$labelSuccess: #00bf3a;
$labelPink: #6214a7;
$subtitleColor: #888788;
$fieldBorder: #d8d8d8;
$embeddedForm: #f3f1eb;
$formFieldRequired: #ff7613;
$formFieldOptional: #ffbd59;
$itemInfoColor: #4a4a4a;

// MAIN SWIMLANE COLORS
$laneColorPrHeader: #d1d1d5;
$laneColorPrBody: #e9eaee;
$laneColorRfxHeader: #fee8ad;
$laneColorRfxBody: #feeec2;
$laneColorOrderHeader: #fee8ad;
$laneColorOrderBody: #feeec2;
$laneColorReceivingHeader: #b3e1f5;
$laneColorReceivingBody: #ccebf8;
$laneColorInvoicesHeader: #bee5b2;
$laneColorInvoicesBody: #cfebc5;

// CARD/MODAL COLORS
$cardColorRfxHeader: #feeec2;
$cardColorRfxBody: #fffdf7;
$cardColorReceivingHeader: #c0e1f5;
$cardColorReceivingBody: #f9fcfe;
$cardColorReceivingCorrespondenceBarHeader: #dcf0fa;

// BACKGROUNDS
$itemPrimaryBackground: rgba(0, 0, 0, 0.05);
$fieldNumberBackground: #c4c4c4;
$tableRowBackground1: rgba(216, 216, 216, 0.2);
$tableRowBackground2: #f2f9f5;
$tableRowBackground3: #efefef;

// HIGHLIGHTERS
$tableFieldHighlight: #e4e4e4;

// RADIUSES
$radiusLarge: 8px;
$radiusSmall: 4px;

// SIZES
$phone-upper-boundary: 600px;
$tablet-portrait-upper-boundary: 900px;
$tablet-landscape-upper-boundary: 1200px;
$desktop-upper-boundary: 1800px;
$headerHeight: 80px;
$addonHeight: 34px;
$shortField: calc(50% - 4px);
$fieldMargin: 16px;

// FONT WEIGHTS
$medium: 500;

// FRAMES
$globalFocus: inset 0 0 0 1px $colorSuccess;
