@import "../../theme/variables";
@import "../../theme/mixins";

.content {
  &-title {
    font-size: 1.1em;
    margin-bottom: 0.5rem;

    &__span {
      font-weight: 400;
    }

    @include for-size(desktop-up) {
      font-size: 1.5em;
    }
  }
}

.summary {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;

  &-item {
    margin-top: 0.5rem;
    padding-right: 0.5rem;
    width: 50%;

    &__value {
      margin-top: 0.2rem;
    }

    @include for-size(tablet-portrait-up) {
      padding-right: 2rem;
      width: 25%;
    }

    &--empty {
      @include for-size(phone-only) {
        display: none;
      }
    }
  }

  &-user {
    display: flex;

    &-details {
      margin-left: 0.5rem;
    }
  }

  @include for-size(tablet-portrait-max) {
    font-size: 0.8rem;
  }

  @include for-size(phone-only) {
    &-user {
      &-details {
        margin-left: 0;
      }

      &__avatar {
        display: none;
      }
    }
  }
}
