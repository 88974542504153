@import "../../theme/variables";
@import "../../theme/mixins";

.wrap {
  margin-top: 1rem;
  width: 100%;
}

.accordion {
  &-title {
    @include for-size(desktop-up) {
      margin-bottom: 1rem;
      border-bottom: 2px solid #cccccc;
      padding-bottom: 0.5rem;

      &--portal {
        text-transform: uppercase;
      }
    }
  }

  @include for-size(tablet-landscape-max) {
    margin-bottom: 1rem;
  }
}

.attachment {
  &-button {
    align-items: center;
    display: flex;

    @include for-size(desktop-up) {
      position: absolute;
      right: 0;
      top: -50px;
    }
  }
}

.attachments {
  margin-bottom: 16px;
}

.content {
  position: relative;

  @include for-size(tablet-landscape-max) {
    margin-top: 15px;
  }

  &-fields {
    max-width: 520px;
  }
}

.suggest {
  @include for-size(tablet-landscape-max) {
    > div > div {
      width: 100%;
    }
  }
}

.input {
  @include for-size(tablet-landscape-max) {
    width: 100%;
  }

  &--hide {
    display: none;
  }
}

.button-add {
  background: transparent;
  color: $colorInput;
  border: 1px solid $colorMedium;

  svg {
    height: 14px;
    margin-right: 6px;
  }

  &:hover,
  &:focus {
    background: transparent;
    border-color: $colorInput;
  }
}

.footer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.excel-item {
  margin-bottom: 16px;
}

.excel-helper {
  height: 32px;
  line-height: 34px;
  padding: 0 5px;

  svg {
    fill: $colorSecondary;
    height: 14px;
  }
}

.excel-container {
  overflow-y: auto;
  overflow-x: scroll;
  margin-bottom: 26px;
  max-width: 1070px;
  max-height: 370px;
}

.wrap--create {
  .excel-container {
    max-width: 1170px;
  }
}

.content--portal {
  .excel-container {
    max-width: none;
  }
}
