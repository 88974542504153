@import "../../theme/variables";

@media print {
  .menu {
    display: none !important;
  }
}

.menu {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  min-width: 800px;
  border-top: 1px solid $colorMedium;
  border-bottom: 1px solid $colorMedium;
  background: $colorShade;

  .section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .section:first-child {
    flex-grow: 1;
  }

  .link {
    display: inline-block;
    padding: 25px 15px;
    color: $colorSecondary;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;

    &:focus {
      outline: none;
      text-decoration: underline;
    }

    &:hover {
      color: $colorSuccess;

      .icon {
        fill: $colorSuccess;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      max-width: 16px;
      height: 16px;
      fill: $colorSecondary;
      transform: translateY(-50%);
    }

    &--has-icon {
      position: relative;
      padding-left: 36px;
    }

    &-sourcing {
      padding-left: 30px;
    }

    &--active {
      position: relative;
      color: $colorSuccess;

      .icon {
        fill: $colorSuccess;
      }
    }
  }
}

// submenu

.submenu {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid $colorMedium;
  background: $colorLight;
}
