@import "../../theme/variables";

.row {
  cursor: pointer;

  &:hover {
    background: $colorHover;
  }
}

.create-new-supplier {
  margin-bottom: 40px;
}

.name-cell {
  font-weight: bold;
}

.name-status-container {
  display: flex;
  justify-content: space-between;
}

.name {
  margin-right: 8px;
  word-break: break-all;
}

.status {
  flex-shrink: 0;
  margin-top: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &--archived {
    fill: $labelRemove;
  }

  &--new {
    background-color: $colorLabel3;
  }

  &--approved {
    background-color: $colorSuccess;
  }

  &--inactive {
    background-color: $labelDefault;
  }
}

.document-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 4px;
  min-width: 21px;
  height: 21px;
  border-radius: 10px;
  background: $colorShade;
}

.no-suppliers {
  padding: 16px 32px;
  text-align: center;
}

.supplier-categories-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 150px;

  & .chip {
    margin-bottom: 4px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: $colorItem4;
    color: white;
  }
}

.center {
  display: block;
  margin: 0 auto;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}
