@import "../../theme/variables";
@import "../../theme/mixins";

.tabs {
  display: flex;

  &--primary {
    margin-bottom: 40px;
    border-bottom: 1px solid $colorMedium;
  }

  &--secondary {
    margin-bottom: 18px;
  }
}

.link {
  &:hover {
    text-decoration: none;
  }

  &--primary {
    margin-bottom: -1px;
    border: 1px solid;
    border-color: #ffffff #ffffff transparent #ffffff;
    border-radius: $radiusSmall $radiusSmall 0 0;
  }

  &--primary-active {
    border-color: $colorMedium $colorMedium #ffffff $colorMedium;
  }

  &--secondary {
    flex-grow: 1;

    + .link--secondary {
      margin-left: 8px;
    }
  }
}

.tab {
  display: block;
  padding: 8px 16px;

  &--secondary {
    @include tabButton;

    flex-basis: 100%;
    flex-grow: 1;

    .triangle-arrow {
      @include tabButtonArrow;
    }
  }

  &--secondary-active {
    @include tabButton;
    @include tabButtonActive;
  }
}
