@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: "Rubik", sans-serif;
}

.item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.quantity-input {
  width: 100px;
}

.quantity-unit {
  margin-top: 6px;
  margin-left: 4px;
  font-weight: 500;
}

.add-button {
  border: 1px solid $colorMedium;
  margin-left: 50px;
  width: 70px;
  height: 16px;
}

.add-button-icon {
  height: 10px !important;
}

.remove-button {
  border: none;
  background: transparent;

  svg {
    fill: orange;
  }

  &:hover {
    box-shadow: none;
  }

  &:focus {
    border-color: orange;
  }
}
