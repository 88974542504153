@import "../../theme/_variables.scss";

.wrap {
  display: flex;
  flex-direction: column;
  margin: 32px;
  width: 100%;
}

.label {
  margin-bottom: 48px;
  color: $colorInput;
  font-weight: 500;
  font-size: 20px;
}

.button-row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  padding: 0 16px;
  height: 48px;
  border: 1px solid $colorSuccess;
  border-radius: 8px;
  background-color: $colorSuccess;
  fill: white;
  color: white;
  font-weight: 400;
  font-size: 18px;

  &:hover {
    border-color: darken($colorSuccess, 4%);
  }

  &:focus,
  &:active {
    border-color: darken($colorSuccess, 8%);
    box-shadow: none;
  }

  & > svg {
    width: 24px;
  }

  & > div {
    margin-left: 8px;
  }

  &--disabled {
    border-color: #eeeeee;
    background-color: #eeeeee;
    fill: #cccccc;
    color: #cccccc;
    cursor: default;

    &:hover {
      border-color: #eeeeee;
      background-color: #eeeeee;
      box-shadow: none;
    }

    &.button--text {
      background-color: transparent;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
