@import "../../theme/variables";

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 32px;
  outline: none;
  border: none;
  border-radius: $radiusSmall;
  text-align: center;
  white-space: nowrap;
  font-weight: $medium;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.4s, border-color 0.4s;

  &.push {
    margin-right: auto;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--loading {
    position: relative;

    .loading-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .loading-content {
      color: transparent;

      user-select: none;
    }
  }

  &--icon {
    padding: 6px;
    min-width: 32px;
    width: auto;
    height: 32px;
    border: 1px solid #ffffff;
    background: #ffffff;

    &:focus {
      border: 1px solid $colorSuccess;
      box-shadow: none;
    }

    &:hover {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    }

    + button {
      margin-left: 8px;
    }

    .icon-text {
      margin-left: 4px;
    }

    svg {
      width: auto;
      width: 15px;
      height: 100%;
    }
  }

  &--large {
    padding: 16px 32px;
    height: 56px;
    font-size: 15px;
    line-height: 22px;
  }

  &--primary {
    background-color: $colorSuccess;
    color: #ffffff;

    &:focus,
    &:hover {
      background-color: darken($colorSuccess, 2%);
    }
  }

  &--secondary {
    border: 1px solid $colorSecondary;
    background: white;
    color: $colorSecondary;

    &:hover,
    &:focus {
      border-color: darken($colorSecondary, 15%);
      color: darken($colorSecondary, 15%);
    }
  }

  &--tertiary {
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    color: $colorSecondary;

    &:hover,
    &:focus {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    }
  }

  &--quaternary {
    background: $colorItem4;
    color: #ffffff;

    &:focus,
    &:hover {
      background-color: darken($colorItem4, 2%);
    }
  }

  &--quentary {
    border-color: $colorMedium;
    background: transparent;
    box-shadow: none;
    font-weight: 400;

    &:hover {
      box-shadow: none;
    }
  }

  &--active {
    border: 1px solid $colorSuccess;
    color: $colorSuccess;

    &:focus,
    &:hover {
      border-color: darken($colorSuccess, 4%);
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      color: darken($colorSuccess, 4%);
    }
  }

  &--add {
    position: relative;
    width: 200px;
    height: 32px;
    background: rgba(0, 0, 0, 0.05);

    &:before,
    &:after {
      position: absolute;
      display: block;
      width: 12px;
      height: 2px;
      background: $colorSuccess;
      content: "";
    }

    &:after {
      transform: rotate(90deg);
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.08);
    }
  }

  &--remove {
    position: relative;
    width: 200px;
    height: 32px;
    background: #f6f7f8;

    &:before,
    &:after {
      position: absolute;
      display: block;
      width: 12px;
      height: 2px;
      background: $colorSuccess;
      content: "";
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.08);
    }
  }

  &--add-loading {
    position: relative;
    width: 200px;
    height: 32px;
    background: rgba(0, 0, 0, 0.05);
  }

  &--warning {
    border: 1px solid $colorError;
    color: $colorError;

    &:hover,
    &:focus {
      border-color: darken($colorError, 10%);
      color: darken($colorError, 10%);
    }
  }

  &--text {
    padding: 0;
    min-width: auto;
    height: auto;
    border: none;
    background: transparent;
    box-shadow: none;
    color: $colorSuccess;
    font-weight: $medium;
    font-size: 13px;

    &:hover {
      box-shadow: none; // remove box shadow of Button with icon prop
      text-decoration: underline;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    .icon-text {
      margin-left: 4px;
    }
  }

  &--disabled {
    background-color: #eeeeee;
    color: #cccccc;
    cursor: default;

    &:hover {
      background-color: #eeeeee;
      box-shadow: none;
    }

    &.button--text {
      background-color: transparent;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--reject {
    border: 1px solid $colorError;
    background: $colorError;
    color: white;

    &:hover,
    &:focus {
      border-color: darken($colorError, 15%);
      background: darken($colorError, 15%);
      color: white;
    }
  }
}
