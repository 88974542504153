@import "../../theme/variables.scss";

.organization {
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-weight: bold;
  font-size: 13px;
  line-height: $headerHeight;
}

.organization-choice {
  align-items: center;

  .selector {
    padding-left: 8px;
    height: $headerHeight;
    font-weight: bold;
    font-size: 13px;
  }

  .content {
    min-width: 248px;
  }
}

.company-logo {
  height: 40px;
}
