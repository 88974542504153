@import "../../theme/variables";

.help-link {
  display: flex;
  align-items: center;
  margin-right: auto;
  padding: 0 5px;
  height: 32px;
  font-weight: 500;
  font-size: 13px;
  line-height: 34px;

  svg {
    margin-right: 4px;
    height: 16px;
    fill: $colorSecondary;
  }
}
