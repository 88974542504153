@import "../../theme/variables";

// Export colors from variables for React component to import and use
:export {
  colorSuccess: $colorSuccess;
  laneColorInvoicesBody: $laneColorInvoicesBody;
  laneColorOrderBody: $laneColorOrderBody;
  laneColorPrBody: $laneColorPrBody;
  laneColorReceivingBody: $laneColorReceivingBody;
  laneColorRfxBody: $laneColorRfxBody;
}

// These variables are actually set in ProgressStep.tsx
// Use these colors to give theme to all elements that need the be the same color
$backgroundActiveColor: var(--backgroundActiveColor);
$symbolActiveBackgroundColor: var(--symbolActiveBackgroundColor);

.wrap {
  display: flex;
  padding: 8px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.06);

  & * {
    font-family: "Rubik", sans-serif;
  }

  &--active {
    background-color: $backgroundActiveColor;
  }
}

.step-symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $symbolActiveBackgroundColor;
  color: $colorLight;
  font-weight: 600;
  font-size: 11px;
  opacity: 1;
}

.title {
  margin-left: 8px;
  color: $colorInput;
  font-weight: 400;
  font-size: 16px;

  &--active {
    font-weight: 600;
  }
}
