@import "../../theme/variables";

.table {
  border-spacing: 0 10px;
  table-layout: fixed;

  thead,
  tbody,
  tfoot {
    td,
    th {
      padding: 14px 16px;
      border-radius: 0;
    }

    th:nth-of-type(1),
    td:nth-of-type(1) {
      width: 300px;
      border-radius: 4px 0 0 4px;
      word-wrap: break-word !important;
    }

    th:nth-of-type(2),
    td:nth-of-type(2) {
      width: 120px !important;
    }

    th:nth-of-type(3),
    td:nth-of-type(3) {
      width: 90px !important;
    }

    th:nth-of-type(4),
    td:nth-of-type(4) {
      width: 135px !important;
    }

    th:nth-of-type(5),
    td:nth-of-type(5) {
      width: 135px !important;
    }

    th:nth-of-type(6),
    td:nth-of-type(6) {
      width: 100px !important;
    }

    td:last-of-type,
    th:last-of-type {
      width: 200px;
      border-radius: 0 4px 4px 0;
    }
  }
}

.table-heading {
  margin: 46px 0 16px 0;
}

.event-row {
  .event-title {
    display: flex;
    white-space: normal;

    &_rfx {
      color: $colorTextContrast;
      white-space: nowrap;
      font-weight: 600;

      &--archived {
        font-weight: 400;
      }
    }
    &_name {
      margin-left: 8px;
      font-weight: 400;
    }
  }
}

.time-left {
  display: flex;

  &_icon {
    width: 16px;
    height: 16px;
    fill: $colorSuccess;
  }

  &_text {
    margin-left: 8px;
    color: $colorSuccess;
    white-space: nowrap;
  }
}

.sourcing-requests {
  display: flex;

  &_icon {
    width: 16px;
    height: 16px;
    fill: $colorSecondary;
  }

  &_text {
    margin-left: 8px;
    color: $colorSecondary;
    font-weight: 600;
  }
}

.quotes-received {
  display: flex;

  &_icon {
    width: 16px;
    height: 16px;
    fill: $colorSuccess;

    &--archived {
      fill: $colorSecondary;
    }
  }

  &_text {
    margin-left: 8px;
    color: $colorSuccess;
    font-weight: 600;

    &--archived {
      color: $colorSecondary;
    }
  }
}

.nominated-supplier {
  font-weight: 600;
  font-size: 12px;
}

.cell {
  background: $tableRowBackground1 !important;
  white-space: nowrap;
}

.statistical-info {
  background: $tableRowBackground2 !important;

  &--archived {
    background: $tableRowBackground3 !important;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.no-data-cell {
  padding: 14px 16px;
  height: 45px;
  border-radius: 4px !important;
  background: $tableRowBackground3;
  font-style: italic;
}
