@import "../../theme/variables";

.container-wrap {
  align-self: flex-start;
  flex-grow: 1;
  margin-bottom: 32px;
  border-radius: $radiusLarge;
  background: $colorLight;

  &--small {
    flex: 0 0 424px;
    width: 424px;
  }

  &--fixed {
    max-height: 400px;
    overflow-y: scroll;
  }
}

.container {
  padding: 16px;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: $radiusLarge $radiusLarge 0 0;
  background: rgba(0, 0, 0, 0.05);
}

.icon {
  margin-right: 8px;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.title {
  font-weight: bold;
}

.items {
  margin-left: auto;
  color: $colorSuccess;
}
