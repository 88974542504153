@import "../../theme/variables";

.wrap {
  margin-bottom: 32px;

  .selection {
    position: relative;
    margin-bottom: $fieldMargin;
    color: $colorInput;

    .name {
      margin-bottom: 8px;
      font-weight: $medium;
    }
  }
}
