@import "../../theme/variables";

.dropdown {
  .selector {
    position: relative;
    width: $headerHeight;
    height: $headerHeight;
    cursor: pointer;

    .counter {
      position: absolute;
      top: calc(50% - 7px);
      right: 23px;
      z-index: 1;
      color: #ffffff;
      font-size: 11px;
      opacity: 0;
      transition: opacity 0.5s ease;
      transform: translateY(-50%);

      &:empty {
        display: none;
      }

      &:after {
        position: absolute;
        top: calc(50% - 1px);
        left: 50%;
        z-index: -1;
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: $colorError;
        content: "";
        transform: translate(-50%, -50%);
      }

      &--active {
        opacity: 1;
        transition: opacity 0.5s ease;
      }
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    transition: transform 0.5s ease;
    transform: rotate(-15deg);

    &--active {
      transition: transform 0.5s ease;
      transform: rotate(0);
    }
  }

  .content {
    width: 320px;
  }
}

.loader {
  position: absolute;
  top: 24px;
  right: 18px;
  z-index: 1;
  width: 16px;
}

// NOTIFICATION LIST ITEM

.list-item {
  $labelWidth: 18px;

  position: relative;
  padding: 15px 15px 10px calc(#{$labelWidth} + 30px);

  &__label,
  &__icon {
    position: absolute;
    left: 15px;
  }

  &__label {
    top: 20px;
    display: inline-block;
    margin-bottom: 2px;
    width: $labelWidth;
    height: 6px;
    border-radius: 3px;
    background: $colorMedium;

    &--success {
      background: $colorSuccess;
    }

    &--error {
      background: $colorError;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    fill: $colorMedium;

    &--success {
      fill: $colorSuccess;
    }
  }

  .date {
    margin-top: 5px;
    color: #888888;
    font-size: 13px;
  }

  .username {
    font-weight: $medium;
  }
}

.notifications-empty {
  text-align: center;
  padding: 20px;
  font-size: 14px;
}

button.expand-list {
  text-align: center;

  &:focus {
    outline: 1px solid $colorSuccess;
  }
}
