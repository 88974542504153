@import "../../theme/variables";
$primaryBackground: rgba(0, 0, 0, 0.05);

.table {
  margin-bottom: 16px;
  width: 100%;
  border-spacing: 3px;
  empty-cells: show;
  font-size: 15px;

  .cell {
    padding: 14px 16px;
    text-align: left;

    &--header {
      font-weight: bold;
    }

    // use for header cells to make the column use as little room as possible
    &--compact {
      width: 1%;
      white-space: nowrap;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &--strong {
      font-weight: bold;
    }

    &--nowrap {
      white-space: nowrap;
    }

    &--primary {
      border-radius: $radiusSmall;
      background: $primaryBackground;
    }

    &--clickable {
      cursor: pointer;
    }

    &--secondary {
      padding: 8px 16px;
      // TODO: move styles from SRM view to here
    }

    &--tertiary {
      padding: 0 16px 0 0;
    }

    &--quaternary {
      padding: 8px;
    }

    &--quinary {
      padding: 0;
    }

    &--success {
      color: $colorSuccess;
    }

    &--error {
      background-color: $colorError;
      color: #ffffff;
    }
  }
}

tbody {
  .cell {
    border-radius: $radiusSmall;
    background: $primaryBackground;

    &--secondary,
    &--tertiary,
    &--quaternary,
    &--quinary {
      background: transparent;
    }
  }
}

th.cell--secondary {
  color: $colorSecondary;
  font-weight: $medium;
}
