@import "../../theme/variables";

.label {
  margin-bottom: 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

.date-range-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-left: 15px;

  :global(.react-datepicker__close-icon) {
    border: 0;
    cursor: pointer;
    outline: 0;
    background-color: transparent;
    vertical-align: middle;

    &:after {
      $size: 16px;
      content: "x";
      position: absolute;
      top: calc(50% + 2px);
      right: 8px;
      width: $size;
      height: $size;
      padding: 0;
      border: none;
      border-radius: 50%;
      outline: none;
      color: #fff;
      line-height: $size;
      font-size: 10px;
      cursor: pointer;
      opacity: 0.7;
      font-weight: normal;
      text-align: center;
      background: $colorSuccess;

      &:hover {
        opacity: 1;
      }
    }
  }

  .date-range-button {
    position: relative;
    width: 100%;
    padding: 0 30px 0 8px;
    height: 32px;
    border: 1px solid $colorMedium;
    border-radius: $radiusSmall;
    text-align: left;
    font-size: 13px;

    &:focus {
      outline: none;
      box-shadow: $globalFocus;
    }
  }

  &--arrow {
    .date-range-button {
      &:after {
        position: absolute;
        top: calc(50% - 2px);
        right: 8px;
        width: 7px;
        height: 7px;
        border-right: 2px solid #222222;
        border-bottom: 2px solid #222222;
        background: transparent;
        content: "";
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
