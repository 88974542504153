@import "../../theme/variables";

.table {
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  gap: 4px;
  margin-bottom: 32px;
  min-width: 100%;
  border-collapse: collapse;

  &[data-state="READONLY"] {
    grid-template-columns: 1fr;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  th,
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th {
    margin-bottom: 8px;
    color: $colorInput;
    text-align: center;
    font-weight: 600;
  }
}

.item-info {
  display: grid;
  grid-template-columns: min-content min-content 1fr max-content;
  grid-template-areas: "item-number icon fields quantities";
  gap: 32px;
  padding: 14px 16px;
  width: 100%;
  border-radius: 4px;
  background-color: $itemPrimaryBackground;

  .item-number {
    display: grid;
    grid-area: item-number;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: $labelDefault;
    color: white;
    font-weight: 700;
    font-size: 13px;
  }

  &-icon {
    grid-area: icon;
    width: 20px;
    height: 20px;
  }

  &-fields {
    display: flex;
    flex-direction: column;
    grid-area: fields;
  }

  &-quantities {
    display: flex;
    grid-area: quantities;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.field-title {
  margin-bottom: 12px;
  color: $colorTextContrast;
  white-space: break-spaces;
  font-weight: 600;
}

.field-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: $itemInfoColor;

  &-icon {
    flex: 0 1 18px;
    margin-right: 20px;
    min-width: 18px;
    min-height: 18px;
    fill: $labelDefault;
    color: $labelDefault;
  }

  &-info {
    margin-right: 4px;
    max-width: 300px;
    white-space: normal;
  }

  &-action {
    margin-left: 8px;
    fill: $formFieldOptional;
    color: $formFieldOptional;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;

    & button {
      color: $formFieldOptional;
      font-size: 10px;
    }
  }
}

.field-attachments {
  margin-top: 36px;
}

.item-question-list {
  margin-top: 16px;
}

.quotation {
  display: flex;
  flex-direction: column;
  padding: 14px 16px;
  border-radius: 4px;
  background-color: $itemPrimaryBackground;
}

.dummy-input {
  margin-bottom: 16px;
}

.quantity {
  display: flex;
  justify-content: flex-end;

  &-input {
    width: 100px;
  }

  &-unit {
    display: flex;
    align-items: center;
    margin-left: 4px;
    font-weight: bold;
  }
}

.actions {
  display: flex;
  justify-content: center;
  padding: 14px 16px;
  border-radius: 4px;
  background-color: $itemPrimaryBackground;

  .button-icon {
    fill: $colorSuccess;
  }
}

.custom-field {
  display: flex;
  flex-direction: column;

  &-label {
    color: $labelDefault;
    font-size: 12px;
  }

  &-value {
    color: $colorInput;
  }
}
