@import "../../theme/variables";

.badge {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 24px;
  border-radius: $radiusSmall;
  background-color: $colorShade;

  svg {
    width: 32px;
    height: 32px;
  }
}

.title {
  margin-left: 8px;
  color: $colorSecondary;
  font-size: 26px;
}
