@import "../../theme/variables";
@import "../../theme/mixins";

@media print {
  .sidebar {
    display: none;
    visibility: hidden;
  }

  .sidebar-wrap {
    width: 0px !important;
  }
}

.sidebar-wrap {
  @include scrollbar;

  position: relative;
  width: 41px;

  &--is-open {
    width: 376px;
  }

  &--open-on-top {
    width: 41px;

    .sidebar-content-wrap {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
    }
  }
}

.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  background: $colorLight;
}

.sidebar-handle {
  position: sticky;
  top: 0;
  margin-left: auto; // pushes handle to the right when content has position absolute (openOnTop: true)
  border-left: 1px solid $colorMedium;
  background: $colorShade;
  color: $colorSecondary;
  cursor: pointer;

  &--closed {
    &:hover {
      opacity: 0.85;
    }
  }
}

.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  svg {
    width: 24px;
    height: 24px;

    fill: $colorSecondary;
  }

  &--sidebar-open {
    background: transparent;

    svg {
      fill: $colorSuccess;
    }
  }
}

.sidebar-title {
  position: absolute;
  top: 40px;
  left: -46px;
  margin: 0;
  white-space: nowrap;
  cursor: inherit;
  transform: rotate(-90deg) translateX(-50%);

  user-select: none;
}

.sidebar-content-wrap {
  overflow: auto;
  width: 336px;
  border-left: 1px solid $colorMedium;
  background: $colorLight;
}

.sidebar-content {
  margin: 4px; // pushes scrollbar to position
  padding: 14px; // creates a 16px gap with 4px margin
  word-break: break-word;
}
