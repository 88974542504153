@import "../../theme/variables";

.no-events-message {
  font-style: italic;
}

.no-data-cell {
  padding: 14px 16px;
  height: 45px;
  border-radius: 4px;
  background: $tableRowBackground3;
  font-style: italic;
}
