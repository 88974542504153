@import "../../theme/variables";

.attachments {
  align-items: baseline;
  color: $colorSecondary;
  font-weight: $medium;
  font-size: 14px;
}

.filename {
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-file-icon {
  margin-right: 8px;
  width: 11px;
  height: 11px;
  fill: $colorError;
  cursor: pointer;
}
