@import "./variables";

// STYLEGUIDE FOR USING TITLES
// h1: view main title
// h2: swimlane titles
// h3: modal titles
// h4: form section titles
// h5: ??? open for use

h1,
h2,
h3,
h4,
h5,
p,
span,
input,
label,
button {
  font-family: "Rubik", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

h1 {
  margin-bottom: 40px;
}

h4 {
  margin-bottom: 16px;
  font-weight: $medium;
  font-size: 15px;
}
