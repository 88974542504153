@import "../../theme/variables";
@import "../../theme/mixins";

.wrap {
  width: 100%;
}

.info-missing {
  margin-top: 8px;
  font-style: italic;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-status {
    margin-right: 0;
  }

  &-details {
    display: flex;
    margin-right: 16px;

    @include for-size(phone-only) {
      flex-wrap: wrap;
    }
  }
}

.info-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.info-item-group {
  display: flex;
  flex: 1 1 320px;

  @include for-size(phone-only) {
    flex-wrap: wrap;
  }
}

.code {
  margin-right: 8px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 25px;
}

.name {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 25px;
  font-family: "Rubik", sans-serif;
}

.item {
  display: flex;
  flex: 1 1 160px;
  flex-direction: column;
  margin: 0 16px 5px 0;
  color: $colorSecondary;
  font-size: 14px;
  line-height: 30px;

  &-name {
    font-weight: 400;
  }

  &-value {
    color: $colorInput;
    white-space: pre-wrap;
    font-weight: 700;
  }

  &-contact {
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }
}

.attachment-list {
  display: flex;
  flex-direction: column;
}

.attachment {
  display: flex;
  align-items: flex-end;
}

.attachment-link {
  display: flex;
}

.filename {
  color: $colorSuccess;
  font-weight: 600;
  font-size: 12px;

  @include for-size(phone-only) {
    word-break: break-all;
  }
}

.file-info {
  display: flex;
  align-items: center;
}

.open-external-icon {
  margin-left: 4px;
  min-width: 11px;
  width: 11px;
  fill: $colorSuccess;
}

.size {
  margin-left: 8px;
  color: $colorSecondary;
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
}

.sourcing-event-link {
  flex: 1;
  margin: 12px 0;
  color: $labelDefault;

  .link-row {
    display: flex;
    align-items: baseline;

    .event-link-title {
      margin-right: 12px;
      white-space: nowrap;
      font-size: 14px;
    }

    .url {
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .instructions {
    margin: 8px 0 0 78px;
    font-size: 10px;
  }
}
