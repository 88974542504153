.loading {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;

  &--padded {
    margin: 12px 0 6px 0;
  }

  .icon {
    width: 32px;
    height: 32px;

    &--small {
      width: 16px;
      height: 16px;
    }

    &--large {
      width: 64px;
      height: 64px;
    }

    &--showable {
      opacity: 0;
      transition: opacity 300ms ease;
    }

    &--show {
      opacity: 1;
    }
  }
}
