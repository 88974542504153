@import "../../theme/variables.scss";

.profile-dropdown {
  position: relative;
  display: flex;
  align-items: center;

  .selector-wrapper {
    display: flex;
    align-items: center;
  }

  .dropdown-selector {
    padding-left: 16px;
    height: $headerHeight;
  }

  .user-wrap {
    text-align: right;
    font-size: 13px;

    .user {
      margin-bottom: 4px;
      font-weight: bold;
    }

    .role {
      color: #999999;
    }
  }

  .dropdown-content {
    left: auto;
    width: 280px;
  }

  .avatar {
    margin-left: 8px;
  }

  .list-item {
    a {
      display: inline-block;
      padding: 16px 24px;
      width: 100%;
      color: #000000;
      text-decoration: none;
    }

    &:hover {
      background-color: #f0f8f3;
    }
  }

  .list-avatar-wrap {
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: default;

    &:hover {
      background: #ffffff;
    }

    .list-avatar {
      margin-right: 8px;
    }

    .list-user-data-wrap {
      display: flex;
      flex-direction: column;
      text-align: left;

      .dropdown-user {
        margin-bottom: 4px;
      }
    }
  }

  .logout-wrap {
    padding: 0;
  }

  .logout {
    display: inline-block;
    padding: 24px;
    width: 100%;
    border-radius: 0;
    background: transparent;
    color: #000000;
    text-align: left;

    &:hover {
      box-shadow: none;
    }
  }
}
