@import "../../theme/variables";
@import "../../theme/mixins";

// LIST
.item-list {
  display: flex;
  flex-direction: column;
}

// ITEM
.item-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.item-info-body {
  border-radius: 8px;
  background-color: white;
}

.subheading {
  color: $colorSecondary;
}

.item-title {
  display: flex;
  margin-bottom: 12px;

  .item-number {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: $labelDefault;
    color: white;
    font-weight: 700;
    font-size: 13px;
  }

  .item-name {
    color: $colorTextContrast;
    font-weight: 600;
  }
}

// PRIMARY INFO
.primary-info {
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 10px 10px 0 20px;
    padding: 14px 20px;
  }

  & > *:last-child {
    @include for-size(tablet-landscape-up) {
      margin-right: 20px;
    }
  }

  .item-info {
    display: flex;
    flex-basis: 300px;
    flex-direction: column;
  }

  .field-item-info {
    @include for-size(phone-only) {
      word-break: break-all;
    }

    &--wide {
      width: unset;
    }
  }

  .offer {
    display: flex;
    flex-direction: column;

    .quantities {
      width: max-content;

      .quantity-price-variant {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 8px;

        @include for-size(phone-only) {
          flex-direction: column;
          align-items: flex-start;
        }

        .item-quantity {
          margin-right: 8px;
          color: $colorTextContrast;
          white-space: nowrap;
          font-weight: 400;

          @include for-size(phone-only) {
            margin-bottom: 4px;
            font-size: 12px;
          }
        }

        .unit-price {
          display: flex;
          align-items: center;

          .unit-price-input {
            margin: 0 8px 0 0;
            width: 100px;
          }

          .currency {
            margin-left: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
          }
        }

        .row-total {
          margin-left: 8px;
          min-width: 150px;
          color: $colorSecondary;
          white-space: nowrap;
          font-style: italic;
          font-size: 12px;

          @include for-size(phone-only) {
            margin: 8px 0 8px 4px;
          }
        }
      }
    }
  }
}

// SECONDARY INFO
.secondary-info {
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex-basis: 300px;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 10px 20px 20px;
    padding: 14px 20px;
    border-radius: 8px;
    background-color: $colorLight;
  }

  & > *:last-child {
    @include for-size(tablet-landscape-up) {
      margin-right: 20px;
    }
  }

  .custom-fields {
    font-size: 14px !important;
  }

  .item-questions {
    font-size: 14px;
  }

  .item-attachments {
    font-size: 14px !important;
  }
}

.field-title {
  margin-bottom: 12px;
  color: $colorTextContrast;
  font-weight: 600;
}

.field-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: $itemInfoColor;

  &-icon {
    margin-right: 16px;
    min-width: 24px;
    min-height: 18px;
    fill: $labelDefault;
    color: $labelDefault;
    white-space: normal;

    & svg {
      max-width: 18px;
    }

    &--wide {
      width: 160px;
    }
  }

  &-info {
    margin-right: 4px;
    width: 100%;
    white-space: normal;
  }

  &-action {
    margin-left: 8px;
    fill: $formFieldOptional;
    color: $formFieldOptional;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;

    & button {
      color: $formFieldOptional;
      font-size: 10px;
    }
  }
}

.custom-field-label {
  color: $labelDefault;
  font-size: 12px;
}

.custom-field-value {
  font-weight: 400;
}

.align-items-flex-start {
  align-items: flex-start;
}

.field-attachments {
  margin-top: 36px;
}

.item-question {
  margin-bottom: 8px;
  max-width: 400px;
  white-space: pre-wrap;
}

.item-answer-input {
  composes: input from "../Field/Field.module.scss";
  composes: textarea from "../Field/Field.module.scss";
}

.supplier-code-input {
  composes: input from "../Field/Field.module.scss";

  max-width: 120px;
}
