@import "../../theme/variables";

.wrap {
  display: none;
  padding: 4px 8px;
  border-radius: 6px;
  color: white;
  font-weight: 400;
  font-size: 12px;

  &[data-state~="SENT"] {
    display: block;
    background-color: $colorSuccess;
  }

  &[data-state~="PENDING"],
  &[data-state~="PREVIEW"] {
    display: block;
    background-color: $colorLabel2;
  }
}

.status {
  font-weight: 400;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  line-height: 16px;
}
