@import "../../theme/variables";

.wrap {
  margin: 0 0 16px 24px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "left-col right-col";
  column-gap: 48px;
  padding: 8px;
}

.left-col {
  display: flex;
  flex-direction: column;
  grid-area: left-col;
}

.right-col {
  display: flex;
  flex-direction: column;
  grid-area: right-col;
}

.add-supplier {
  margin-bottom: 8px;
  padding: 16px 16px 0 16px;
  border-radius: 4px;
  background-color: $laneColorOrderBody;
}

.supplier-info {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  background-color: $colorHover;

  > :nth-child(n + 2) {
    margin-left: 16px;
  }

  > :nth-child(1) {
    padding-left: 16px;
  }

  > :nth-child(2) {
    padding-right: 16px;
  }

  .name-fields {
    display: table-cell;
  }
}

.supplier-form-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  background-color: $colorHover;

  > :nth-child(n + 2) {
    margin-left: 16px;
  }
}

.email-form {
  margin-bottom: 40px;
}

.sourcing-event-link {
  flex: 1;
  margin-bottom: 12px;
  color: $labelDefault;

  .link-row {
    display: flex;
    align-items: baseline;

    .event-link-title {
      margin-right: 12px;
      white-space: nowrap;
      font-size: 14px;
    }

    .url {
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .instructions {
    margin: 8px 0 0 78px;
    font-size: 10px;
  }
}
