@import "../../theme/variables";

.image-wrap {
  display: flex;
  justify-content: center;
}

.image {
  width: 100%;

  object-fit: contain;
}
