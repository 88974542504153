@import "../../theme/variables";

.wrap {
  width: calc(50% - 4px);
  background: white;
  padding: 7px;
  border: 1px solid $colorMedium;
  border-radius: 4px;
  color: $colorSecondary;
  font-size: 15px;
  position: relative;
  margin-bottom: 16px;
  max-height: 220px;
  overflow-y: auto;
}

.item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 2px 0;

  span {
    align-items: center;
    display: flex;
  }

  svg {
    height: 14px;
  }
}

.isLoading {
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
