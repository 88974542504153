@import "../../theme/variables";

.list {
  display: flex;
  flex-direction: column;
}

.list-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);

    .remove-button {
      visibility: visible;
    }
  }
}

.first-col {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 46px;
}

.remove-button {
  visibility: hidden;
  margin-bottom: 14px;
  width: 12px;
  height: 12px;
  fill: red;
  cursor: pointer;

  &:hover {
    width: 14px;
    height: 12px;
  }
}

.list-item-sequence {
  & .sequence-num {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 8px 0;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: $fieldNumberBackground;
    color: white;
    font-size: 10px;
    font-family: "Rubik", sans-serif;
  }
}

.list-item-fields {
  padding: 0;
}

.title-input div {
  font-size: 14px;
}

.value-input {
  color: $colorInput;
}

.add-button {
  margin-left: 28px;
  width: 70px;
  height: 16px;
}

.add-button-icon {
  height: 10px !important;
}
