@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: $fieldMargin;
  min-width: 200px;
  width: 100%;
}

.editor {
  padding: 7px;
  min-height: 120px;
  border: 1px solid $colorMedium;
  border-radius: $radiusSmall;
  background-color: white;
  color: $colorInput;
  font-size: 15px;
  line-height: 1.2;
  transition: all 200ms ease;
}

.has-focus {
  border-color: $colorSuccess;
  transition: all 200ms ease;
}
