@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.accordion-contents {
  margin-left: 32px;
}

.date-picker-container {
  display: flex;
  align-items: center;
}

.date-picker-time-ago {
  margin-left: 8px;
  font-style: italic;
  font-size: 12px;
}

.two-columns {
  display: flex;
}

.additional-info {
  flex: 1;
  margin-right: 36px;
}

.second-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.attachment-list {
  flex: 1;

  &-title {
    color: $colorSecondary;
    font-weight: 500;
    font-size: 14px;
  }
}

.upload-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0 0 24px;
}

.attachment-item {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.filesize {
  margin-left: 8px;
  color: $colorSecondary;
  font-weight: 600;
  font-size: 12px;
}

.delete-file-icon {
  margin-right: 4px;
  width: 11px;
  height: 11px;
  fill: $colorError;
  cursor: pointer;
}
