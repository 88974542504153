@import "../../theme/variables";
@import "../../theme/mixins";

@media print {
  .header {
    display: none !important;
  }
}

.header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0 12px 0 20px;
  height: 80px;
  background: #ffffff;

  .logo {
    align-items: center;
    display: flex;
    margin-right: 16px;
    height: 56px;
    cursor: pointer;
  }

  .section {
    display: flex;

    &--secondary {
      align-items: flex-end;
      flex-direction: column;
    }
  }

  &-portal {
    @include for-size(phone-only) {
      position: fixed;
      left: 0;
      right: 0;
      z-index: 2;
      top: 0;
    }
  }
}

.viewer {
  font-weight: bold;
  font-size: 14px;
}

.company {
  max-height: 40px;
}

.title {
  color: $colorSecondary;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
}
