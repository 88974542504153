@import "../../theme/variables";

.wrap {
  width: 100%;
}

.suggestion {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 8px;
  color: $colorInput;
  font-weight: $medium;
  cursor: pointer;

  &:hover,
  &--highlight {
    background: $colorHover;
  }

  &--used {
    opacity: 0.5;
  }

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;

    &--category {
      padding: 4px 12px;
      height: 28px;
      border-radius: 10px;
      background-color: $colorItem8;
      color: $colorLight;
      font-size: 18px;
    }
  }

  .email {
    padding: 4px 0;
    font-size: 12px;
  }

  .text-highlight {
    font-weight: normal;
  }

  .supplier-status {
    align-self: flex-start;
    margin-left: 8px;
    padding: 4px;
    border-radius: 4px;
    background-color: green;
    color: $colorLight;
    font-size: 8px;
    line-height: 1;

    &--new {
      background-color: $colorLabel3;
    }

    &--approved {
      background-color: $colorSuccess;
    }

    &--inactive {
      background-color: $colorSecondary;
    }

    &--archived {
      background-color: $colorError;
    }
  }
}

.srm-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 156, 226, 0.2);
  color: $colorTextContrast;
  font-size: 12px;
}

.contact-person-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(200, 200, 200, 0.2);
  background-position: center;
  background-size: cover;
  font-size: 12px;

  & svg {
    width: 20px;
    height: 20px;
  }
}
