@import "../../theme/variables";

.form-section {
  margin-bottom: 42px;
}

.category-autosuggest {
  width: 343px;
}

.supplier-categories-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 24px 0;
  max-width: 343px;

  & .chip {
    margin-bottom: 4px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: $colorItem4;
    color: white;
  }
}

// deletion confirmation
.align-left {
  margin-right: auto;
}

.wrap {
  display: flex;
  justify-content: center;
  gap: $fieldMargin;
}