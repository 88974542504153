.ready-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  fill: white;
}

.custom-fields-list {
  margin-bottom: 16px;
}
