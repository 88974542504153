@import "../../theme/variables";

// these variables are set in Grid.tsx
$gridTemplateColumns: var(--gridTemplateColumns);
$gridTemplateAreas: var(--gridTemplateAreas);

.grid {
  display: grid;
  grid-template-columns: $gridTemplateColumns;
  grid-template-rows: auto;
  grid-template-areas: $gridTemplateAreas;
  width: 100%;
}
