@import "../../theme/variables";

.outer-wrap {
  &--short {
    width: $shortField;
  }

  .field-wrap {
    display: flex;

    > * {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
