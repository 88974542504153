@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 33px;
  width: 100%;
  font-family: "Rubik", sans-serif;
}

// text display mode in all states
.text-display {
  $self: &;

  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;

  // there is no value yet
  &[data-state~="INIT"] {
    #{ $self }__value {
      flex: 1;
      color: $colorSuccess;
      font-weight: 400;
      font-style: italic;

      &:hover {
        font-weight: 500;
      }
    }

    #{ $self }__icon {
      display: block;
      margin-left: 8px;
      width: 20px;
      height: 20px;
      fill: $colorSuccess;
    }
  }

  // there exists a valid value already
  &[data-state~="SUCCESS"] {
    #{ $self }__value {
      flex: 1;
      color: $colorInput;
      font-weight: 500;

      &:hover {
        color: $labelDefault;
      }

      &:hover + #{ $self }__icon {
        display: block;
        margin-left: 8px;
        width: 20px;
        height: 20px;
        fill: $labelDefault;
      }
    }

    #{ $self }__icon {
      display: none;
    }
  }

  // do not show if in editing or failure state
  &[data-state~="EDITING"],
  &[data-state~="FAILURE"] {
    display: none;
  }
}

// input mode in all states
.input-display {
  display: flex;
  align-items: center;
}

.input-field {
  $self: &;
  composes: input from "../Field/Field.module.scss";

  // do not show when in init or success state
  &[data-state~="INIT"],
  &[data-state~="SUCCESS"] {
    display: none;
  }

  &[data-state~="FAILURE"] {
    border: 1px solid $colorError;
  }
}

// reset icon
.reset-icon {
  display: none;

  &[data-state~="EDITING"],
  &[data-state~="FAILURE"] {
    display: block;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    fill: red;
    cursor: pointer;

    &:hover {
      stroke: red;
      stroke-width: 8px;
    }
  }
}

// error message
.input-error {
  display: none;
  max-width: 300px;

  &[data-state~="FAILURE"] {
    display: block;
    margin-top: 4px;
    margin-left: 8px;
    color: $colorError;
  }
}
