@import "../../theme/variables";
@import "../../theme/mixins";

.view {
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @include for-size(phone-only) {
    justify-content: center;
    margin: auto;
  }
}

.section {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  padding: 40px;
  min-width: 504px;
  max-width: 750px;
  width: calc(100% / 3);

  @include for-size(phone-only) {
    min-width: 320px;
    width: 100%;
  }
}

.logo__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;

  .logo {
    margin-right: 16px;
  }
}

.form {
  max-width: 100%;
  width: 450px;

  @include for-size(phone-only) {
    width: 100%;
  }

  .input {
    width: 100%;

    input {
      padding: 0 16px;
      height: 56px;
    }
  }
}

.forgotten__password__link {
  display: block;
  margin-top: 40px;
  color: $colorSuccess;
  text-align: center;
  text-decoration: none;
}

// avoids login view layout changing / title jumping once email is sent
.login__form__wrap {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
}

.login__success_wrapper {
  text-align: center;

  p {
    margin-bottom: 16px;
  }
}

.reset__form__link {
  display: inline-block;
  margin-top: 40px;
  color: $colorSuccess;
  text-decoration: none;
}

// ILLUSTRATION
.illustration__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background: #f0f7f1;

  @include for-size(phone-only) {
    display: none;
  }

  .illustration {
    display: none;

    @include for-size(tablet-landscape-up) {
      display: inherit;
      padding: 40px;
      max-width: 100%;
      width: 568px;
    }
  }
}
