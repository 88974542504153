@import "../../theme/variables";

.accordion-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--secondary {
    .title * {
      color: $colorSecondary;
      font-size: 11px;
    }

    .arrow-icon {
      left: 0;
      width: 10px;
      height: 10px;
      fill: $colorSecondary;
    }

    .accordion-header {
      margin-bottom: 14px;
    }

    .accordion {
      padding-left: 14px;
    }
  }
}

.accordion-header {
  display: flex;
  margin-bottom: 16px;
}

.accordion {
  position: relative;
  padding-left: 32px;
  min-height: 16px;
  width: 100%;
  border: none;
  background: transparent;

  &:focus {
    outline: none;
  }
}

.icon {
  flex: 1 0 24px;
  max-width: 24px;
  max-height: 24px;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 12px;
  height: 12px;
  fill: $colorInput;
  transform: translateY(-50%);

  &--closed {
    transform: translateY(-50%) rotate(-90deg);
  }
}

.title {
  margin: 0;

  * {
    color: $colorInput;
  }
}

.children-state {
  &[data-state~="OPEN"] {
    display: block;
  }

  &[data-state~="CLOSED"] {
    display: none;
  }
}
