.debug {
  margin: 1rem 0;
  border: 1px solid #000000;
  background-color: #ffffff;
  white-space: pre;

  &:last-child {
    margin-bottom: 0;
  }

  .title {
    padding: 0.5rem 1rem;
    background-color: #000000;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .content {
    overflow: auto;
    padding: 0.5rem;
    font-size: 0.75rem;
    font-family: "Courier New", Courier, monospace;
  }
}
