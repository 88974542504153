@import "../../theme/variables";

.add-button {
  margin-bottom: 40px;
}

.button-icon {
  fill: $colorSuccess;
}

.avatar {
  // FIXME
  cursor: default;
}
