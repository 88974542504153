.names {
  display: flex;
  padding-top: 12px;
}

.email {
  width: calc(min(100%, 375px));
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 0 8px 16px 0;
}
