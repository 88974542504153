@import "../../theme/variables";

.kanban {
  display: flex;
  flex-direction: row;
}

.image {
  object-fit: contain;
}

.feed {
  background: #eff0f2;
}

.update-notice-container {
  position: fixed;
  top: 4px;
  left: 50%;
  z-index: 1;
  transform: translate(-50%);
}
