@import "../../theme/variables";
@import "../../theme/mixins";

.accordion {
  .content {
    display: none;
  }

  .title {
    cursor: pointer;
    position: relative;

    h3 {
      padding-left: 30px;
    }
  }

  .arrow {
    height: 8px;
    display: block;
    position: absolute;
    top: 6px;
    left: 7px;
    transition: transform 0.1s ease;
    transform: rotate(-90deg);

    &--open {
      transform: rotate(0deg);
    }
  }

  &--small {
    .content {
      background: white;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .arrow {
      fill: $colorSecondary;
      height: 6px;
      top: 9px;
      left: 0;
    }

    .title {
      border-bottom: 1px solid $colorShade;
      color: $colorSecondary;
      font-size: 11px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;

      h3 {
        align-items: center;
        display: flex;
        font-size: 11px;
        font-weight: 500;
        padding-left: 14px;
      }
    }
  }

  &--open {
    > .content {
      display: block;
    }
  }

  &--secondary {
    .content {
      background: transparent;
    }

    > .title {
      cursor: default;

      h3 {
        padding-left: 0;
      }
    }
  }

  @include for-size(tablet-portrait-max) {
    margin-top: 0.5rem;

    .arrow {
      display: block;
      fill: white;
      height: 10px;
      transition: rotate 0.4s;
      width: 16px;
      top: 11px;
      left: 10px;
    }

    .content {
      display: none;
    }

    .title {
      align-items: center;
      background-color: $labelDefault;
      border-radius: 4px;
      color: white;
      display: flex;
      font-size: 0.85rem;
      height: 30px;
      justify-content: space-between;
      overflow: hidden;
      padding: 6px;
      transition: background-color 0.4s;
    }

    &--small {
      .arrow {
        fill: $colorSecondary;
        height: 6px;
        top: 11px;
        left: 0 !important;
      }

      .title {
        background: none !important;
        color: $colorSecondary;
        display: flex;
        margin-bottom: 0;
        width: 100%;
      }
    }

    &--open {
      > .title {
        background-color: $labelInfo;
      }

      > .content {
        display: block;
      }
    }

    &--secondary {
      .arrow {
        display: block;
        left: auto;
        right: 5px;
      }
    }
  }

  @include for-size(desktop-up) {
    &--secondary {
      .arrow {
        display: none;
      }
    }

    &--small {
      .arrow {
        display: block;
      }
    }
  }
}
