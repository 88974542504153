@import "~react-datepicker/dist/react-datepicker.css";
@import "normalize";
@import "variables";
@import "typography";
@import "mixins";

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Rubik-Regular, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
}

a {
  color: $colorSuccess;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

input[readonly],
textarea[readonly] {
  background-color: $colorReadonly;

  &:active,
  &:focus {
    border-color: $colorMedium;
  }
}
