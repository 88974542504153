@import "../../theme/variables";

.filename {
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &--secondary {
    max-width: 145px;
    color: $colorSecondary;
    font-weight: 400;
  }
}

.url-attachment {
  display: flex;
  align-items: center;

  &-link {
    display: flex;
    align-items: baseline;
    color: $colorSuccess;
    font-weight: $medium;
    font-size: 14px;
  }
}

.delete-file-icon {
  margin-right: 8px;
  width: 11px;
  height: 11px;
  fill: $colorError;
  cursor: pointer;
}

.open-external-icon {
  margin-left: 4px;
  min-width: 11px;
  width: 11px;
  fill: $colorSuccess;
}

.file-size {
  margin-left: 4px;
  white-space: nowrap;
  font-size: 10px;
}
