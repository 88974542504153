@import "../../theme/variables";
@import "../../theme/mixins";

.view {
  @include scrollbar;

  display: flex;
  flex-basis: 100vh;
  flex-direction: column;
  height: 100vh;
}

.menu {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  min-height: 60px;
  background: $colorShade;
}

.content-wrap {
  overflow: auto;
  color: $colorInput;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 24px auto 100px auto;
  padding: 10px 10px 0;
  max-width: 1200px;
  font-style: normal;
  font-family: Rubik;
}

.status-wrap {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 10;
  max-width: 784px;
  transform: translateX(-50%);
}

.title {
  margin-top: 40px;
  color: $colorLightTitle;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
}

.heading {
  color: #000000;
  white-space: nowrap;
  font-weight: bold;
  font-style: normal;
  font-size: 25px;
  font-family: Rubik;
  line-height: 30px;

  @include for-size(phone-only) {
    font-size: 20px;
  }
}

.links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-content: center;
  justify-self: end;

  &--import {
    display: block;
    font-weight: normal;
    border: 0;
  }
}


.quotation-heading {
  display: grid;
  align-items: center;
  margin-bottom: 16px;

  .heading {
    margin-bottom: 0;
  }

  .excel-link {
    margin-left: auto;
    font-size: 12px;
  }

  &--links {
    grid-template-columns: 1fr 1fr;
  }
}

.questionnaire,
.items {
  display: flex;
  flex-direction: column;
  margin-left: 28px;
}

.questionnaire {
  margin-left: 0;
  max-width: 806px;
}

.items {
  display: flex;
  flex-direction: column;

  @include for-size(phone-only) {
    margin-left: 0;
  }
}

.questionnaire-actions {
  display: flex;
  justify-content: flex-end;
}

.questionnaire-no-questions {
  margin-left: 28px;
  font-style: italic;
}

.form-actions {
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 16px;
  }
}

.comments-field {
  composes: input from "../../components/Field/Field.module.scss";

  margin: 0 0 16px 28px;
  max-width: 806px;

  @include for-size(phone-only) {
    margin-left: 0;
  }
}

.add-files {
  margin-left: 28px;

  @include for-size(phone-only) {
    margin-left: 0;
  }
}

.offer-info {
  margin-bottom: 24px;
  color: $colorInput;
  text-align: right;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
}

.overdue {
  margin-bottom: 24px;
  color: $colorError;
  text-align: right;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
}

.label {
  composes: label from "../../components/FieldLabel/FieldLabel.module.scss";

  margin: 0 0 36px 28px;
  margin-bottom: 16px;
  color: #000000;
  text-transform: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}
