@import "../../theme/variables";

.description {
  color: $colorSecondary;
  font-size: 14px;

  &--center {
    display: block;
    text-align: center;
  }

  &--spacious {
    display: block;
    margin-bottom: 16px;
  }
}
