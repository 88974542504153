.mentions {
  margin: 16px 0;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 2px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 2px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-size: 14px;
  font-family: "Rubik", sans-serif;
}
.mentions--multiLine .mentions__highlighter {
  padding: 9px;
  min-height: 63px;
  border: 1px solid transparent;
  line-height: 1.5;
}
.mentions--multiLine .mentions__input {
  padding: 9px;
  outline: 0;
  border: 1px solid silver;
  line-height: 1.5;
}

.mentions__suggestions__list {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: white;
  font-size: 10px;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  padding: 2px 0 2px 2px;
  border-radius: 5px;
  background-color: #cee4e5;
}

.mentions__tag {
  padding: 2px;
  border-radius: 5px;
  background-color: #c2acbf;
}
