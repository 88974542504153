@import "../../theme/variables";

// Export colors from variables for React component to import and use
:export {
  colorLight: $colorLight;
  labelAlert: $labelAlert;
  labelDefault: $labelDefault;
  labelInfo: $labelInfo;
  labelPink: $labelPink;
  labelSuccess: $labelSuccess;
  labelWarning: $labelWarning;
}

// The --backgroundColor is actually set in LabelDropdown.tsx
// Use this color to give theme to all elements that need the be the same color
$backgroundColor: var(--backgroundColor);
$labelTextColor: var(--labelTextColor);

.label-dropdown {
  border-radius: 4px;
  background-color: $backgroundColor;

  &--open {
    border-radius: 4px 4px 0 0;
  }
}

.dropdown {
  position: static;
}

.dropdown-selector-container {
  display: inline-flex;
  color: $labelTextColor;
}

.dropdown-selector-item {
  padding: 6px 8px;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  font-family: Rubik;
  line-height: 15px;
}

.dropdown-button-container {
  padding: 0 0 0 4px;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.dropdown-selector-button {
  margin: 4px 4px 4px 0;
  width: 20px;
}

.dropdown-content {
  padding: 8px 8px 0 8px;
  width: 100%;
  border: 1px solid $backgroundColor;
  border-radius: 0 0 4px 4px;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-family: Rubik;
  line-height: 14px;
}

.dropdown-item {
  display: flex;
  margin-bottom: 8px;
}

.dropdown-item-icon {
  flex: 0 0 14px;
  margin-right: 4px;
  fill: $backgroundColor;
}

.dropdown-item-text {
  margin: 0;
  font-size: 12px;
}

.triangle-arrow-icon {
  width: 15px;
  fill: $labelTextColor;
}
