@import "../../theme/variables";

.wrap {
  margin: 0 0 40px;
  padding: 10px 20px;
  width: 100%;
  background-color: $colorError;
  color: #ffffff;
  text-align: center;
}
