@import "../../theme/variables";

.datepicker {
  margin-bottom: $fieldMargin;

  .field-wrap {
    display: flex;
    align-items: center;
  }

  .input {
    padding: 8px;
    height: $addonHeight;
    border: 1px solid $colorMedium;
    border-top-right-radius: $radiusSmall;
    border-bottom-right-radius: $radiusSmall;
    color: $colorInput;
    font-size: 14px;

    &:focus {
      outline: none;
      border: 1px solid $colorSuccess;
    }
  }

  .addon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $addonHeight;
    height: $addonHeight;
    border: 1px solid $colorMedium;
    border-right: none;
    border-top-left-radius: $radiusSmall;
    border-bottom-left-radius: $radiusSmall;
    background: $colorShade;
  }

  .icon {
    width: 20px;
    height: 20px;

    fill: $colorSecondary;
  }

  .clear {
    padding: 8px;
    color: $colorSuccess;
    text-transform: uppercase;
    font-weight: $medium;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
