.avatar {
  position: absolute;
  top: 20px;
  right: 20px;
}

.view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  background-color: #ffffff;

  &--secondary {
    overflow: auto;
    padding: 40px calc(50% - 1264px / 2);
    width: 100%;
  }
}
