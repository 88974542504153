@import "../../theme/variables";

.value-pair {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &--align {
    align-items: center;
  }
}

.name {
  margin-right: 32px;

  &--highlight {
    font-weight: bold;
  }
}

.value {
  display: flex;
  text-align: right;
  font-weight: $medium;
  word-break: break-word;

  > :nth-child(n + 2) {
    margin-left: 8px; // if more than 1 child, then give margin
  }
}

.zero-margin-bottom {
  margin-bottom: 0;
}
