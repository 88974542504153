@import "../../theme/variables";

.approval-badge {
  align-items: center;
  border-radius: $radiusSmall;
  color: white;
  display: inline-flex;
  font-size: 11px;
  font-weight: $medium;
  padding: 4px 4px 2px;

  &--approved {
    background-color: $labelSuccess;
  }

  &--cancelled {
    background-color: $labelWarning;
  }

  &--declined {
    background-color: $labelAlert;
  }

  &--draft {
    background-color: $labelDefault;
  }

  &--inprogress {
    background-color: $labelInfo;
  }
}
