@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
  width: 100%;
  border-radius: 16px;
  background-color: $colorItem5;
}

.title {
  color: $colorLightTitle;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.event-name {
  margin-top: 25px;
  color: $colorTextContrast;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
}

.event-info {
  margin-top: 25px;
  color: $labelDefault;
  font-weight: 400;
  font-size: 16px;

  .table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    table-layout: fixed;
  }

  .row {
    display: table-row;
  }

  .cell {
    display: table-cell;
    padding: 4px 0;
    vertical-align: top;
  }

  .first-col {
    width: 168px;
  }

  .second-col {
    width: auto;
  }

  .name-with-avatar {
    display: flex;
    align-items: center;

    .creator-name {
      margin-right: 8px;
    }
  }
}
