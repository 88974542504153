@import "../../theme/variables";

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.users-filter {
  margin-right: 16px;
}

.icon-sidebar {
  margin-left: 16px;
  width: 22px;
  height: 22px;
  cursor: pointer;

  fill: $colorSecondary;

  &--active {
    fill: $colorSuccess;
  }
}

.dropdown-avatar {
  margin-right: 8px;
}

.filter-icon {
  width: 16px;

  &--active {
    fill: $colorSuccess;
  }
}
