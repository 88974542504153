@import "../../theme/variables";

.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 20%;
  height: 100vh;
  text-align: center;
}

.title {
  margin-bottom: 1em;
}

.message {
  line-height: 2em;
}

.error {
  margin-top: 2em;
  padding: 20px 40px;
  background-color: $colorError;
  color: #ffffff;
}
