@import "../../theme/variables";

.wrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &--with-title {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  &--zero-margin-top {
    margin-top: 0;
  }

  &--zero-margin-bottom {
    margin-bottom: 0;
  }
}

.separator {
  flex-grow: 1;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.085);
}

.title {
  padding-right: 8px;
  color: #666666;
  font-weight: $medium;
  font-size: 15px;
}

.info-text {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.085);
  font-style: italic;
  font-size: 13px;
  opacity: 0.5;
}
