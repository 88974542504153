@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: $colorLight;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  line-height: 2;
}

.action {
  margin-top: 20px;
}
