@import "../../theme/variables";

.wrap {
  margin-bottom: 25px;
  width: 100%;
}

.body {
  margin-left: 18px;
}

.question-ro {
  margin-bottom: 16px;
  font-size: 15px;
  line-height: 18px;
}

.answer-ro {
  margin-left: 12px;
  color: $colorTertiary;
  font-size: 15px;
  line-height: 18px;
}

.field-container {
  display: flex;
  flex-direction: column;
}

.field {
  composes: input from "../Field/Field.module.scss";
}

.label {
  composes: label from "../FieldLabel/FieldLabel.module.scss";

  text-transform: none;
  font-size: 14px;

  &--required {
    composes: label--required from "../FieldLabel/FieldLabel.module.scss";
  }
}

.error-message {
  color: $colorError;
  white-space: normal;
  font-size: 12px;
}
