@import "../../theme/variables";

.wrap {
  width: 100%;
}

.list {
  margin-top: 8px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.name {
  display: block;
  overflow: hidden;
  margin: 0 5px;
  max-width: 260px;
  color: $colorSecondary;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  line-height: 1;
  transition: color 0.3s ease;
}

.link {
  display: flex;
  color: $colorSecondary;
  transition: color 0.3s ease;

  &:hover {
    color: $colorSuccess;

    .name {
      color: $colorSuccess;
    }
  }
}

.icon {
  margin-bottom: 2px;
  height: 9px;

  &--open {
    fill: $colorSuccess;
  }
}

.attachments {
  align-items: baseline;
  margin-bottom: 16px;
  color: $colorSecondary;
  font-weight: $medium;
  font-size: 13px;
}

.attachment-item {
  margin-top: 8px;
}

.delete-file-icon {
  margin-right: 4px;
  width: 8px;
  height: 8px;
  fill: $colorError;
  cursor: pointer;
}
