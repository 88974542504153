@import "../../theme/variables";

.suggestion {
  display: flex;
  padding: 8px 16px 8px 8px;
  color: $colorInput;
  font-weight: $medium;
  cursor: pointer;

  &:hover,
  &--highlight {
    background: $colorHover;
  }

  &--used {
    opacity: 0.5;
  }

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
  }

  .email {
    padding: 4px 0;
    font-size: 12px;
  }

  .text-highlight {
    font-weight: normal;
  }
}
