@import "../../theme/variables";
@import "../../theme/mixins";

.feed-update {
  display: flex;
  margin-bottom: 16px;
  font-size: 14px;
}

.update-icon {
  margin-right: 8px;
}

.graph-icon {
  align-self: flex-start;
  padding: 3px 6px;
  border-radius: $radiusSmall;
  background: $colorShade;

  fill: $colorInput;

  svg {
    width: 12px;
  }
}

.section-update {
  flex: 1;
  padding-top: 3px;
  max-width: calc(100% - 30px);
}

.author {
  margin-right: 8px;
  color: $colorSuccess;
  font-weight: $medium;
}

.author-action {
  margin-right: 8px;
}

.date {
  color: $colorSecondary;
  font-size: 12px;
}

.message {
  margin-bottom: 4px;
  padding: 8px 0;
  line-height: 1.5;
}

.message-content-container {
  position: relative;
}

.message-content {
  flex: 1;

  &--latest {
    &::before {
      position: absolute;
      right: calc(100% + 8px);
      height: 100%;
      border: 2px solid $colorLabel1;
      content: "";
    }
  }
}

.reply-message {
  margin-bottom: 4px;
  padding: 8px 0;
  line-height: 1.5;

  &::before {
    position: absolute;
    bottom: 0;
    left: 10px;
    height: calc(100% - 32px);
    border: 1px dashed $colorShade;
    content: "";
  }
}

.reply-message-content-container {
  position: relative;
}

.reply-content {
  flex: 1;

  &--latest {
    &::before {
      position: absolute;
      right: calc(100% + 8px);
      height: 100%;
      border: 2px solid $colorLabel1;
      content: "";
    }
  }
}

.respond-wrap {
  display: flex;
  justify-content: space-between;
}

.respond {
  margin-left: auto;
  color: $colorSecondary;
  font-size: 12px;
}

.reply-wrap {
  padding-left: 24px;
  list-style: none;
  font-size: 14px;
}

.reply {
  .reply-wrap {
    padding-left: 0;
  }
}

.loading-wrapper {
  margin-bottom: 16px;
}

.inline-button {
  display: inline;
}

.email-wrap {
  cursor: pointer;
}

.email-body {
  @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: #f8f8fa);
}

.file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
  padding-right: 16px;
}

.attachment {
  display: flex;
  align-items: baseline;
  color: $colorSecondary;
  font-weight: $medium;
  font-size: 10px;
}

.filename {
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.open-external-icon {
  margin-left: 4px;
  min-width: 11px;
  width: 11px;

  fill: $colorSuccess;
}

.reply-input {
  margin-left: auto;
  width: calc(100% - 30px);
}
