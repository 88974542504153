@import "../../theme/variables";

.wrap {
  display: flex;
  align-items: center;
}

.checkbox span:first-of-type {
  width: 16px;
  height: 16px;
}

.attachment-link {
  display: flex;
}

.filename {
  color: $colorSuccess;
  font-weight: 600;
}

.open-external-icon {
  margin-left: 4px;
  min-width: 11px;
  width: 11px;
  fill: $colorSuccess;
}

.size {
  margin-left: 8px;
  color: $colorSecondary;
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
}
