@import "../../theme/variables";

.modal {
  background: $colorItem3;
}

.wrap {
  margin: -16px -16px 0 0;
}

.label-dropdown {
  align-self: center;
}

.addon-wrap {
  display: flex;
  gap: $fieldMargin;
  position: relative;
}

.invoice-container {
  display: grid;
  grid-template-columns: minmax(auto, 1fr) minmax(0, auto);
  grid-template-areas: "left right";
  min-height: 600px;
}

.left-container {
  grid-area: left;
  margin: 12px 8px 0 0;
  padding-right: 12px;
}

.right-container {
  overflow-x: hidden;
  grid-area: right;
  border-left: 1px solid rgba(0, 0, 0, 0.085);
}

.supplier {
  margin-bottom: 16px;
  font-weight: $medium;
}

.label-complete {
  display: flex;
  align-items: center;
  font-weight: $medium;
}

.archive-icon {
  fill: $labelRemove;
}
