@import "../../theme/variables";

.wrap {
  position: relative;
  display: inline-block;
  outline: 0;
  svg {
    margin-bottom: -2px;
    cursor: pointer;
  }
}

.content {
  position: absolute;
  z-index: 100;
  overflow: hidden;
  margin: 0;
  padding: 0;
  // closed state
  width: 0; // stylelint-disable-line
  border-radius: 8px;
  background: $colorSecondary;
  box-shadow: 0 0 20px -1px rgba(46, 48, 51, 0.15);
  line-height: 16px;
  opacity: 0;
  transition: opacity 0.4s;

  will-change: opacity;

  // opened state
  &.opened {
    overflow: visible;
    margin-bottom: 8px;
    padding: 12px 36px 12px 16px;
    width: 300px;
    opacity: 1;
  }
}

.info {
  color: #ffffff;
  white-space: pre-line;
  font-size: 13px;
}

.arrow {
  position: absolute;
  margin-top: 12px;
  border-width: 6px;
  border-style: solid;
  border-color: $colorSecondary transparent transparent transparent;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  color: #ffffff;
  cursor: pointer;
}
