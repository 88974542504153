@import "../../theme/variables";
@import "../../theme/mixins";

.wrap {
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "avatar email-to toggle"
    "left extra-info right"
    "left body right"
    "left footer right";
  align-items: center;
  padding: 8px;
  max-width: 800px;
  width: 100%;
  background-color: $embeddedForm;
  color: $colorInput;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
}

// GENERAL
.field-label {
  font-weight: 500;
}

.field-value {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 11px;
}

// AVATAR AREA
.avatar {
  display: flex;
  grid-area: avatar;
}

// EMAIL-TO AREA
.email-to {
  display: flex;
  flex-direction: column;
  grid-area: email-to;
}

// TOGGLE AREA
.toggle-cell {
  display: none;

  @include for-size(tablet-portrait-up) {
    display: block;
    grid-area: toggle;
    align-self: flex-start;
    margin-top: 8px;
  }
}

.toggle-button {
  padding: 0;
  height: 6px;
  border: none;
  background: transparent;
  fill: $subtitleColor;
  box-shadow: none;
  opacity: 0.55;

  &:hover {
    border: none;
    box-shadow: none;
  }

  &:active,
  &:focus {
    border: none;
    box-shadow: none;
  }
}

// EXTRA-INFO AREA
.extra-info {
  grid-area: extra-info;
}

.email-from {
  grid-area: from;
}

.email-subject {
  margin-top: 8px;
  font-weight: 400;
}

// BODY AREA
.body-area {
  display: flex;
  flex-direction: column;
  grid-area: body;
}

.cc-toggle-container {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  position: relative;
  margin-right: 4px;
  color: $colorSecondary;
  font-weight: 400;
  font-size: 10px;

  &:before {
    position: absolute;
    left: -12px;
    display: block;
    width: 8px;
    height: 1px;
    background: $colorError;
    content: "";
  }
}

.reply-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

// FOOTER AREA
.footer-area {
  grid-area: footer;
}

.footer-button-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.attachments-button {
  margin-right: auto;
}

.footer-button {
  justify-self: flex-end;
  margin-right: 8px;
}

.add-button {
  position: relative;
  margin-right: 4px;
  color: $colorSecondary;
  font-weight: 400;
  font-size: 10px;

  &:before,
  &:after {
    position: absolute;
    left: -12px;
    display: block;
    width: 8px;
    height: 1px;
    background: $colorSuccess;
    content: "";
  }

  &:after {
    transform: rotate(90deg);
  }
}

.attachments {
  align-items: baseline;
  margin-bottom: 16px;
  color: $colorSecondary;
  font-weight: $medium;
  font-size: 10px;
}

.attachment-item {
  margin-top: 8px;
}

.delete-file-icon {
  margin-right: 4px;
  width: 8px;
  height: 8px;
  fill: $colorError;
  cursor: pointer;
}
